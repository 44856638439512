/* eslint-disable no-fallthrough */

import {
  addQueryParamsToUrl,
  generateUrl,
  updatePathParamsForApiUrl
} from '../utils/UrlGenerator'

import HttpConstants from '../constants/HttpConstants'
import StringConstants from '../constants/StringConstants'
import UrlConstants from '../constants/UrlConstants'
import axios from 'axios'
import cookie from 'react-cookies'
import { rootStore } from '../store/StoreContext'
import routeConstants from '../constants/RouteConstants'

function makeApiCallWithAuthentication(
  usecase: string,
  httpMethod: string,
  data: any
) {
  return makeApiCallInternal(usecase, httpMethod, true, data)
}

function makeApiCall(usecase: string, httpMethod: string, data: any) {
  return makeApiCallInternal(usecase, httpMethod, false, data)
}

function makeApiCallInternal(
  usecase: string,
  httpMethod: string,
  authenticatedCall: boolean,
  data: any
) {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (
        error.response?.status === 401 &&
        !error.response.request.responseURL.includes('/login') &&
        !error.response.request.responseURL.includes('/member') &&
        !error.response.request.responseURL.includes('/project') &&
        !error.response.request.responseURL.includes('/details')
      ) {
        window.location.href = routeConstants.UNAUTHORIZED_PAGE
      }
      return Promise.reject(error)
    }
  )
  // eslint-disable-next-line prefer-const
  let { url, params } = updatePathParamsForApiUrl(
    generateUrl(usecase) as string,
    data
  )
  const scope = rootStore.scopeStore.getScope()
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  rootStore.uiStore.setAxiosSource(source)
  const scopeName = UrlConstants[usecase].SCOPE
  let headers: any = {
    headers: {}
  }

  if (authenticatedCall) {
    headers = {
      headers: {
        Authorization: 'Bearer ' + cookie.load(StringConstants.COOKIE_TOKEN)
      }
    }
  }

  switch (scopeName) {
    //noFallthroughCasesInSwitch flag in tsconfig.json is set to  false to suppress the error
    //TODO: localize error suppression to this block and set noFallthroughCasesInSwitch flag to true
    case 'environment':
      headers.headers['environment-id'] = scope['environment-id']
    case 'project':
      headers.headers['project-id'] = scope['project-id']
    case 'organization':
      headers.headers['organization-id'] = scope['organization-id']
      break
    default:
  }

  // in case of create environment, do not use project id from global state
  // since environment can be created for any project in settings
  if (
    [
      UrlConstants.CREATE_ENVIRONMENT.USECASE,
      UrlConstants.FIND_ALL_ENVIRONMENTS.USECASE,
      UrlConstants.CREATE_USER_PROJECT_INVITE.USECASE,
      UrlConstants.FIND_PROJECT.USECASE,
      UrlConstants.DELETE_PROJECT.USECASE,
      UrlConstants.GET_PROJECT_MEMBER_FILTERS.USECASE,
      UrlConstants.FIND_PROJECT_MEMBERS.USECASE,
      UrlConstants.REMOVE_PROJECT_MEMBER.USECASE,
      UrlConstants.UPDATE_PROJECT_MEMBER.USECASE,
      UrlConstants.UPDATE_PROJECT.USECASE,
      UrlConstants.ADD_PROJECT_MEMBER.USECASE,
      UrlConstants.ENVIRONMENT_NAME_AVAILABILITY.USECASE,
      UrlConstants.UNASSIGNED_ORGANIZATION_MEMBER_LOOK_UP_FOR_PROJECT.USECASE,
      UrlConstants.DISCONNECT_PROJECT_TO_SLACK.USECASE
    ].includes(usecase)
  ) {
    headers.headers['project-id'] = data.projectId
    delete data.projectId
  }

  if (usecase === UrlConstants.ON_BOARD_USER_VIA_SSO.USECASE) {
    headers.headers['code'] = data.code
    delete data.code
  }

  if (
    [
      UrlConstants.FIND_ENVIRONMENT.USECASE,
      UrlConstants.UPDATE_ENVIRONMENT.USECASE,
      UrlConstants.DELETE_ENVIRONMENT.USECASE,
      UrlConstants.GET_ENVIRONMENT_FILTER.USECASE
    ].includes(usecase)
  ) {
    headers.headers['project-id'] = data.projectId
    headers.headers['environment-id'] = data.environmentId
    delete data.projectId
    delete data.environmentId
  }

  if (
    [
      UrlConstants.GET_HEALTH_SEARCH_CONTEXTS.USECASE,
      UrlConstants.GET_EXCEPTION_SEARCH_CONTEXTS.USECASE,
      UrlConstants.GET_JOB_SEARCH_CONTEXTS
    ].includes(usecase)
  ) {
    if (data.includeCancelToken) {
      headers.cancelToken = source.token

      delete data.includeCancelToken
    }
  }

  if (usecase === UrlConstants.CONNECT_TO_SALESFORCE.USECASE) {
    headers.headers['code'] = data.code
    headers.headers['environment-id'] = data.headers.environmentId
    headers.headers['project-id'] = data.headers.projectId
    headers.headers['organization-id'] = data.headers.organizationId
  }

  switch (httpMethod) {
    case HttpConstants.GET_METHOD:
      if (params) {
        url = addQueryParamsToUrl(url, params, HttpConstants.GET_METHOD)
      }
      return axios.get(url, headers).catch((err) => {
        throw err.response
      })
    case HttpConstants.POST_METHOD:
      if (usecase === UrlConstants.REGISTER_USER.USECASE) {
        url = addQueryParamsToUrl(url, params, HttpConstants.POST_METHOD)
      }
      return axios.post(url, params, headers).catch((err) => {
        throw err.response
      })
    case HttpConstants.PATCH_METHOD:
      if (
        usecase === UrlConstants.UPDATE_ORGANIZATION_MEMBER.USECASE ||
        usecase === UrlConstants.UPDATE_PROJECT_MEMBER.USECASE ||
        usecase === UrlConstants.UPDATE_EXCEPTION_GROUP.USECASE
      ) {
        url = addQueryParamsToUrl(url, params, HttpConstants.PATCH_METHOD)
      }
      return axios.patch(url, params, headers).catch((err) => {
        throw err.response
      })
    case HttpConstants.DELETE_METHOD:
      if (params) {
        url = addQueryParamsToUrl(url, params)
      }
      return axios.delete(url, { data: params, ...headers }).catch((err) => {
        throw err.response
      })
    default:
      throw new Error('No HTTP Method found')
  }
}

export default {
  makeApiCallWithAuthentication,
  makeApiCall
}
