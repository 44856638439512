import {
  AtoB,
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../../utils/UrlUtils'
import React, { useEffect, useState } from 'react'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import {
  findAllEnvironments,
  getEnvironmentFilters,
  updateEnvironment
} from '../../../../../api/environment/Environment.service'
import {
  getAllProjects,
  getProjectById
} from '../../../../../api/project/Project.service'

import { Button } from '../../../../../components/New/Button/Button'
import { ChevronRight } from '@carbon/icons-react'
import CopyOnClick from '../../../../../components/common/CopyOnClick/CopyOnClick'
import CustomErrorToast from '../../../../../components/common/Toast/CustomErrorToast'
import { DDSTypography } from 'den-design-system'
import { IoIosTimer } from 'react-icons/io'
import NoRecord from '../../../../../components/common/NoContent/NoRecord'
import { OrganizationUserRoles } from '../../../../../enum/OrganizationUserRoles.enum'
import { ProjectType } from '../../../../../enum/ProjectType.enum'
import { SettingAddPopup } from '../../SettingPopups/SettingAddPopup'
import SettingConstants from '../../../../../constants/SettingConstants'
import Spinner from '../../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../../constants/StringConstants'
import Switch from '../../../../../components/New/Switch/Switch'
import Table from '../../../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../../../components/New/Tooltip/Tooltip'
import { TypographyDDS } from '../../../../../components/common/Typography/TypographyDDS'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import { paginate } from '../../../../../utils/CommonUtils'
import pallete from '../../../../../global/newPallete'
import routeConstants from '../../../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { timeFormats } from '../../../../../enum/TIME'
import { timeZone } from '../../../../../utils/DateTimeUtils'
import { toast } from 'react-toastify'
import { useStoreContext } from '../../../../../store/StoreContext'

interface Project {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const ElipsisContainer = styled(TypographyDDS.Paragraph)<{ column?: string }>`
  height: 18px;
  width:${({ column }) => (column === 'jobName' ? 'calc(90%);' : 'calc(80%);')}
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Environments: React.FC<Project> = ({ loading = false, setLoading }) => {
  const navigate = useNavigate()

  const { projectId, environmentId } = useParams()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentTab, setCurrentTab] = useState(0)

  const [projectName, setProjectName] = useState<string>()

  const { pathIds } = useParams()
  const { pathname } = useLocation()

  const [hostTableData, setHostTableData] = useState<any>([])

  const [searchParams, setSearchParams] = useSearchParams()
  const [addHostPopupOpen, setAddHostPopupOpen] = React.useState<boolean>(false)
  const [deleteHostPopupOpen, setDeleteHostPopupOpen] =
    React.useState<boolean>(false)
  const [notificationPopupOpen, setNotificationPopupOpen] =
    React.useState<boolean>(false)
  const [environmentFilters, setEnvironmentFilters] = useState([])
  const [notificationProps, setNotificationProps] = useState<any>({
    fullItem: null,
    checked: false
  })

  const location = useLocation()
  const store = useStoreContext()

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  const isProduction = window.location.host.includes('app.vigilnow.com')

  const routeAddhost = (environmentId: number) => {
    const route = replaceUrl(
      routeConstants.ADDHOST,
      store.scopeStore.getScope()['organization-id'] as string,
      projectId,
      String(environmentId)
    )
      .replace(':projectId', String(projectId))
      .replace(':environmentId', String(environmentId))

    navigate({
      pathname: route
    })
  }

  const paginatedData = paginate(hostTableData, currentPage, 10)

  const getFilters = () => {
    getEnvironmentFilters(projectId as string)
      .then((response: any) => {
        setEnvironmentFilters(response.data)
        getCurrentTab()
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTINGS,
            store.scopeStore.getScope()['organization-id'] as string,
            '',
            ''
          )
        )
      })
  }

  const handleNotificationPreference = (fullItem: any, checked: boolean) => {
    store.uiStore.setGlobalLoader(true)
    updateEnvironment({
      sendEmailNotifications: checked,
      projectId: fullItem?.projectId,
      environmentId: fullItem?.id
    })
      .then(() => {
        setNotificationPopupOpen(false)
        getEnvironments()
        store.uiStore.setGlobalLoader(false)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.ENVIRONMENT.UPDATE.NOTIFICATION.SUCCESS
        })
      })
      .catch((err) => {
        setNotificationPopupOpen(false)
        ToastNotification({
          type: 'error',
          message: err
        })
      })
  }

  const getCurrentTab = () => {
    const type = searchParams.get('type')
    const environmentTypes = ['DEVELOPMENT', 'STAGING', 'PRODUCTION']
    if (type && environmentTypes.includes(type)) {
      setCurrentTab(environmentTypes.indexOf(type) + 1)
    } else {
      setCurrentTab(0)
    }
  }

  const getEnvironments = () => {
    store.environmentStore.setLoading(true)
    setLoading(true)
    const allSearchParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const pageSize = StringConstants.DEFAULT_TABLE_SIZE
    const queryParams: any = {
      ...allSearchParams,
      size: pageSize,
      'expand-created-user': true
    }
    findAllEnvironments(projectId as string, queryParams)
      .then((environments: any) => {
        if (
          environments.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        } else {
          store.filterStore.setFilterDisabled(true)
        }
        store.environmentStore.setEnvironments(environments.data)

        store.environmentStore.setTotalCount(environments.totalCount)

        store.environmentStore.setCurrentPage(environments.page)

        store.filterStore.setFilters(environments.filters)
        setLoading(false)
        store.environmentStore.setLoading(false)
      })
      .catch((err) => {
        store.environmentStore.setLoading(false)
        store.environmentStore.setEnvironments([])
        store.environmentStore.setTotalCount(0)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      })
      .finally(() => store.uiStore.setGlobalLoader(false))
  }

  const fetchAllProjects = () => {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    getAllProjects({ ...queryParams, 'expand-created-user': true })
      .then((response: any) => {
        store.projectStore.setProjects(response.data)
      })
      .catch((error) => {
        toast.error(
          <CustomErrorToast
            heading={ToastMessageConstants.PROJECT.FIND_ALL_PROJECTS.ERROR}
            message={error}
          />
        )
      })
  }

  useEffect(() => {
    store.scopeStore.setOrganizationId(
      AtoB(pathIds as string).split('/')[0] as string
    )
    store.environmentStore.setLoading(true)
    if (isEmpty(environmentFilters)) {
      getFilters()
    }
    if (location.search !== '' || store.filterStore.getFiltersApplied()) {
      getEnvironments()
      fetchAllProjects()
    }
  }, [location.search])

  useEffect(() => {
    const project = store.scopeStore
      .getProjects()
      .filter((record: any) => record.id === projectId)
    store.scopeStore.setSelectedProject(project[0])
    store.projectAndEnvironmentStore.setSelectedProject(project[0])

    if (project.length > 0) {
      store.scopeStore.setSelectedProject(project[0])
      store.projectAndEnvironmentStore.setSelectedProject(project[0])
    }
  }, [store.projectStore.getProjects(), store.scopeStore.getSelectedProject()])

  useEffect(() => {
    getProjectById(projectId as string)
      .then((response: any) => {
        setProjectName(response.data.name)
      })
      .catch((err: string) => {
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTING,
            store.scopeStore.getScope()['organization-id'] as string,
            '',
            ''
          )
        )

        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.PROJECT.FIND_PROJECT.ERROR
        })
      })
  }, [store.scopeStore.getProjects()])

  useEffect(() => {
    const breadcrumbsOptions = store.breadcrumbStore.getBreadcrumbsOptions()

    if (breadcrumbsOptions.length === 0) {
      const projectName = store.scopeStore
        .getProjects()
        .find((project) => project.id === projectId)?.name

      store.breadcrumbStore.addMultipleBreadCrumbOptions([
        { label: 'Project Settings', link: routeConstants.PROJECT_SETTING },
        {
          label: projectName || 'Unknown Project',
          link:
            routeConstants.VIEW_PROJECT.replace(
              ':projectId',
              projectId as string
            ) + '?tab=1'
        },
        { label: 'Environments', link: '/' }
      ])
    }

    return () => {
      store.breadcrumbStore.reset()
    }
  }, [projectId, store.scopeStore, store.breadcrumbStore])

  const columns = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.NAME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'type',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text == StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
              ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
              : StringConstants.ENVIRONMENT_NAMES.PROD}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '26%' },
      columnDataStyle: { width: '26%' },
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {isFullStackProject ? 'API Key' : 'Email Address'}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: isFullStackProject ? 'apiKey' : 'emailAddress',
      render: (item: any, data: any) => {
        return item ? (
          isFullStackProject ? (
            <div style={{ display: 'flex' }}>
              <Tooltip label={item} id={`tooltip-name-${item}`}>
                <div
                  style={{
                    maxWidth: '280px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                >
                  <ElipsisContainer
                    size='para'
                    variant='medium'
                    color='textDark'
                  >
                    {item}
                  </ElipsisContainer>
                </div>
              </Tooltip>
              <div>
                <CopyOnClick value={item} />
              </div>
            </div>
          ) : data.emailStatus === 'CREATED' ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!isProduction &&
              (data.emailInboxId === null || data.emailInboxId === '') ? (
                <div>-</div>
              ) : (
                <>
                  <Tooltip label={item} id={`tooltip-name-${item}`}>
                    <div
                      style={{
                        maxWidth: '300px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center'
                      }}
                    >
                      <ElipsisContainer
                        size='para'
                        variant='medium'
                        color='textDark'
                      >
                        {item}
                      </ElipsisContainer>
                    </div>
                  </Tooltip>
                  <div>
                    <CopyOnClick value={item} />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className='flex items-center justify-center'>
              {!isProduction &&
              (data.emailInboxId === null || data.emailInboxId === '') ? (
                <div>-</div>
              ) : (
                <>
                  <Tooltip
                    label='E-mail inbox is getting initialised'
                    id={`tooltip-name-${item}`}
                  >
                    <div className='flex items-center justify-center'>
                      <IoIosTimer size={20} />
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
          )
        ) : (
          '-'
        )
      }
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.CREATED_ON}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'createdAt',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              text,
              timeFormats.fullMonthDateYear
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.NOTIFICATIONS}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'sendEmailNotifications',
      render: (item: any, fullItem: any) => (
        <div className='flex justify-center cursor-pointer'>
          <Switch
            onChange={(checked: boolean) => {
              if (!checked) {
                !notificationPopupOpen && setNotificationPopupOpen(true)
                setNotificationProps({
                  fullItem: fullItem,
                  checked: checked
                })
              } else {
                handleNotificationPreference(fullItem, checked)
              }
            }}
            id='toggle-notification'
            value={item}
            disabled={loading || store.environmentStore.getLoading()}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer flex justify-end text-end row items-end'>
          <Button
            id='delete-project'
            type='neutral'
            endIcon={
              <ChevronRight size={16} color={pallete.colors.neuButtonText} />
            }
            size='small'
            label='View'
            onClick={() => {
              routeAddhost(fullItem.id)
            }}
            disabled={
              loading ||
              store.uiStore.getGlobalLoader() ||
              store.userStore.getUserRole() === OrganizationUserRoles.BILLING ||
              store.userStore.getNoActiveSubscription()
            }
          />
        </div>
      )
    }
  ]

  return (
    <>
      {store.uiStore.getGlobalLoader() ||
      loading ||
      store.environmentStore.getLoading() ? (
        <Spinner />
      ) : (
        <div className='py-[24px]'>
          <div className='mb-[32px]'>
            <DDSTypography.Title
              type='h3'
              variant='semiBold'
              color={pallete.colors.textDark3}
            >
              {SettingConstants.ENVIRONMENTS}
            </DDSTypography.Title>
          </div>
          <div>
            {store.environmentStore.getEnvironments().length !== 0 ||
            store.environmentStore.getLoading() ? (
              <Table
                tableStyle={{
                  borderColor: pallete.colors.stroke2
                }}
                data={store.environmentStore.getEnvironments()}
                columns={columns}
                showPagination={false}
                totalCount={store.environmentStore.getTotalCount()}
                rowsPerPage={10}
                currentPage={store.environmentStore.getCurrentPage()}
                onPageChange={(page: number) => {
                  const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  setSearchParams(
                    { ...allQueryParams, page: page.toString() },
                    { replace: true }
                  )
                  if (page !== store.environmentStore.getCurrentPage())
                    store.environmentStore.setEnvironments([])
                }}
              />
            ) : (
              <NoRecord
                imageSrc=''
                text='Add environments you want to monitor in!'
              />
            )}
          </div>

          <SettingAddPopup
            showHeader
            title='Notifications'
            modalOpen={notificationPopupOpen}
            setModalOpen={setNotificationPopupOpen}
            primaryButtonText='Yes'
            loading={store.environmentStore.getLoading()}
            primaryButtonDisabled={store.environmentStore.getLoading()}
            secondaryButtonDisabled={store.environmentStore.getLoading()}
            secondaryButtonText='No'
            primaryButtonClick={() =>
              handleNotificationPreference(
                notificationProps.fullItem,
                notificationProps.checked
              )
            }
            secondaryButtonClick={() => {
              setNotificationPopupOpen(false)
            }}
            showFooter={true}
            content={
              <div className='flex flex-col p-[24px] gap-[24px]'>
                <DDSTypography.Title
                  type='h4'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                >
                  {SettingConstants.TOGGLE}

                  <span style={{ fontWeight: '700' }}>
                    {''}{' '}
                    {notificationProps?.fullItem?.type === 'PROD'
                      ? 'Prod'
                      : notificationProps?.fullItem?.type === 'NON_PROD'
                        ? 'Non Prod'
                        : notificationProps?.fullItem?.type}
                    {''} Environment
                  </span>
                </DDSTypography.Title>
              </div>
            }
          />
        </div>
      )}
    </>
  )
}

export default observer(Environments)
