import {
  CreateProject,
  getAllProjects,
  getProjectAndEnvironment,
  getProjectFilters
} from '../../../../api/project/Project.service'
import Joi, { ValidationResult } from 'joi'
import {
  ProjectType,
  ProjectTypeDisplayNames
} from '../../../../enum/ProjectType.enum'
import React, { useEffect, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../utils/UrlUtils'

import { Button } from '../../../../components/New/Button/Button'
import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../../../components/Helmet/DocumentHeader'
import FullstackIcon from '../../../../assets/icons/fullstack.svg'
import { HeaderConstants } from '../../../../constants/HeaderConstants'
import Input from '../../../../components/New/Input/Input'
import MulesoftIcon from '../../../../assets/icons/newMulesoft.svg'
import NoFilterImage from '../../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../../components/common/NoContent/NoRecord'
import { OrganizationType } from '../../../../enum/OrganizationType.enum'
import { OrganizationUserRoles } from '../../../../enum/OrganizationUserRoles.enum'
import RegexConstants from '../../../../constants/RegexConstants'
import SalesforceIcon from '../../../../assets/icons/newSalesforce.svg'
import Select from '../../../../components/New/Select/Select'
import { SettingAddPopup } from '../SettingPopups/SettingAddPopup'
import SettingConstants from '../../../../constants/SettingConstants'
import Spinner from '../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../constants/StringConstants'
import Table from '../../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../../components/New/Tooltip/Tooltip'
import UrlConstants from '../../../../constants/UrlConstants'
import ValidationConstants from '../../../../constants/ValidationConstants'
import { checkNameAvailability } from '../../../../api/common/Common.service'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { timeFormats } from '../../../../enum/TIME'
import { timeZone } from '../../../../utils/DateTimeUtils'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useStoreContext } from '../../../../store/StoreContext'

const ProjectSettings = () => {
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const [projectFilters, setProjectFilters] = useState([])
  const [projectType, setProjectType] = useState<string>('')
  const [projectName, setProjectName] = useState<string>('')
  const [isNameAvailable, setIsNameAvailable] = useState<any>(null)
  const [checkLoading, setCheckLoading] = useState<boolean>(false)

  const [createProjectPopupOpen, setCreateProjectPopupOpen] =
    React.useState<boolean>(false)
  const [page, setPage] = useState<number>(
    parseInt(searchParams.get('page') as string) || StringConstants.DEFAULT_PAGE
  )

  const validateProjectNameSchema = Joi.string()
    .pattern(RegexConstants.NAME_REGEX)
    .required()

  const validateProjectTypeSchema = Joi.string().required()

  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.PROJECT_SETTINGS
  )
  const findAllProjects = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const queryParams = {
      page,
      size: StringConstants.DEFAULT_TABLE_SIZE,
      ...allQueryParams,
      'expand-created-user': true
    }
    setSearchParams({ page: '1', ...allQueryParams })
    if (!store.projectStore.getLoading()) {
      store.uiStore.setGlobalLoader(true)
      store.projectStore.setLoading(true)
      getAllProjects(queryParams)
        .then((response: any) => {
          if (
            response.data.length > 0 ||
            store.filterStore.getFiltersApplied() ||
            !store.projectStore.getLoading()
          ) {
            store.filterStore.setFilterDisabled(false)
          } else {
            store.filterStore.setFilterDisabled(true)
          }

          store.projectStore.setProjects(response.data)
          store.projectStore.setCurrentPage(response.page)
          store.projectStore.setTotalCount(response.totalCount)

          store.filterStore.setFilters(response.filters)
        })
        .catch((error) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.PROJECT.FIND_ALL_PROJECTS.ERROR
          })
        })
        .finally(() => {
          store.uiStore.setGlobalLoader(false)
          store.projectStore.setLoading(false)
        })
    }
  }

  const getFilters = () => {
    store.filterStore.setFilterDisabled(true)
    getProjectFilters()
      .then((response: any) => {
        setProjectFilters(response.data)
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      })
  }

  const createProject = () => {
    store.projectStore.setLoading(true)
    const type = projectType

    setProjectType(type)

    CreateProject({
      name: projectName,
      projectType: type
    })
      .then(() => {
        store.projectStore.setProjects([])
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.PROJECT.CREATE.SUCCESS
        })
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          )
        )
        setProjectName('')
        setProjectType('')
        setCreateProjectPopupOpen(false)
        store.projectStore.setLoading(false)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })
        setProjectName('')
        setProjectType('')
        setCreateProjectPopupOpen(false)
        store.projectStore.setLoading(false)
      })
  }

  const checkAvailability = () => {
    setCheckLoading(true)
    checkNameAvailability(
      UrlConstants.CHECK_PROJECT_NAME_AVAILABILITY.USECASE,
      projectName
    )
      .then((response: any) => {
        setIsNameAvailable(true)
        setCheckLoading(false)
        createProject()
      })
      .catch((error) => {
        setCheckLoading(false)
        ToastNotification({
          type: 'error',
          message:
            'Project name is already taken. Please enter a different name'
        })
        setIsNameAvailable(false)
      })
  }

  const updateProjectsAndEnvironments = () => {
    getProjectAndEnvironment().then((projectResponse: any) => {
      store.scopeStore.setProjects(projectResponse.data)
    })
  }

  useEffect(() => {
    getFilters()
    findAllProjects()
    updateProjectsAndEnvironments()
  }, [
    currentPage,
    store.scopeStore.getSelectedProject(),
    store.scopeStore.getSelectedEnvironment(),
    location.search,
    store.scopeStore.getOrganizationId()
  ])

  useEffect(() => {
    const organizationId = store.scopeStore.getScope()[
      'organization-id'
    ] as string
    const projectId = store.scopeStore.getScope()['project-id'] as string
    const environmentId = store.scopeStore.getScope()[
      'environment-id'
    ] as string

    const breadcrumbsOptions = store.breadcrumbStore.getBreadcrumbsOptions()

    if (breadcrumbsOptions.length === 0) {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: 'Project Settings',
        link: replaceUrl(
          routeConstants.PROJECT_SETTING,
          organizationId,
          projectId,
          environmentId
        )
      })
    }

    return () => {
      store.breadcrumbStore.reset()
    }
  }, [store.scopeStore, store.breadcrumbStore])

  const columns = [
    {
      columnHeaderStyle: { width: '400px' },
      columnDataStyle: { width: '400px' },
      heading: (
        <div className='flex justify-start text-start  row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.PROJECT_NAME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: '',
      render: (text: any, fullItem: any) => {
        return (
          <div className='flex justify-start text-start row items-start'>
            <div>
              <Tooltip
                id={`tooltip-name-${fullItem?.name}`}
                label={fullItem?.name ? fullItem?.name : '-'}
                className={`${
                  typeof fullItem?.name === 'string' &&
                  fullItem?.name.length > 25
                    ? fullItem?.name
                    : '!hidden'
                }`}
                position='top'
              >
                <div>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark4}
                    className='tooltip-container sm:block flex items-center overflow-hidden max-w-[400px]'
                    style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  >
                    {fullItem?.name}
                    <span className='flex items-center ml-[8px]'>
                      {fullItem?.type === ProjectType.SALESFORCE_ONLY && (
                        <img
                          src={SalesforceIcon}
                          sizes='16px'
                          width='16px'
                          height='16px'
                        />
                      )}
                      {fullItem?.type === ProjectType.MULESOFT_ONLY && (
                        <img
                          src={MulesoftIcon}
                          sizes='16px'
                          width='16px'
                          height='16px'
                        />
                      )}
                      {fullItem?.type === ProjectType.FULL_STACK_ONLY && (
                        <img
                          src={FullstackIcon}
                          sizes='16px'
                          width='16px'
                          height='16px'
                        />
                      )}
                    </span>
                  </DDSTypography.Paragraph>
                </div>
              </Tooltip>
            </div>
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '400px' },
      columnDataStyle: { width: '400px' },
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.CREATED_BY}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'createdByUser',
      render: (text: any) => (
        <Tooltip
          id={`tooltip-name-${text}`}
          label={text?.firstName ? text?.firstName : '-'}
          className={`${
            typeof text?.firstName === 'string' && text?.firstName.length > 25
              ? text?.firstName
              : '!hidden'
          }`}
        >
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate max-w-[400px] '
            >
              {text?.firstName}
            </DDSTypography.Paragraph>
          </div>
        </Tooltip>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {SettingConstants.CREATED_ON}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'createdAt',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              text,
              timeFormats.monthDateYear
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnDataStyle: { width: '3%' },
      columnHeaderStyle: { width: '3%' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer flex justify-end text-end row items-end'>
          <ChevronRight
            size={16}
            onClick={() => {
              gaEventTracker(gaEventConstants.VIEW_PROJECT)

              const queryParams = { tab: '1' }
              navigate({
                pathname: `./${fullItem.id}/`,
                search: `?${createSearchParams(queryParams)}`
              })
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]

  const { userStore } = useStoreContext()

  const ProjectTypeDropdownOptions = [
    {
      label: ProjectTypeDisplayNames.SALESFORCE_ONLY,
      value: ProjectType.SALESFORCE_ONLY
    },
    {
      label: ProjectTypeDisplayNames.FULL_STACK_ONLY,
      value: ProjectType.FULL_STACK_ONLY
    },
    {
      label: ProjectTypeDisplayNames.MULESOFT_ONLY,
      value: ProjectType.MULESOFT_ONLY
    }
  ]

  const SFecosystemProjectDropdownOptions = [
    {
      label: ProjectTypeDisplayNames.SALESFORCE_ONLY,
      value: ProjectType.SALESFORCE_ONLY
    },
    {
      label: ProjectTypeDisplayNames.MULESOFT_ONLY,
      value: ProjectType.MULESOFT_ONLY
    }
  ]

  const fullStackProjectDropdownOptions = [
    {
      label: ProjectTypeDisplayNames.FULL_STACK_ONLY,
      value: ProjectType.FULL_STACK_ONLY
    }
  ]

  return (
    <>
      <DocumentHeader header={HeaderConstants.PROJECT_SETTINGS} />
      {store.projectStore.getLoading() ? (
        <Spinner />
      ) : (
        <div className='flex flex-col py-[24px]'>
          <div className='flex pb-[24px]'>
            <div className='flex w-full  custom-filter'>
              {/* <Filter
                dropdownLabel='Add Filter'
                options={projectFilters}
                disabled={
                  store.projectStore.getLoading() ||
                  store.uiStore.getGlobalLoader() ||
                  store.userStore.getNoActiveSubscription()
                }
              /> */}
            </div>
            <Button
              id='create-project'
              type='filled'
              size='small'
              disabled={
                store.projectStore.getLoading() ||
                store.uiStore.getGlobalLoader() ||
                store.userStore.getNoActiveSubscription() ||
                store.userStore.getUserRole() !== OrganizationUserRoles.OWNER
              }
              label='Create Project'
              onClick={() => setCreateProjectPopupOpen(true)}
            />
          </div>
          <div className='flex justify-between items-center'>
            <div className='w-1/3'>
              {/* <Search
              placeholder='Search'
              id='log-search'
              name='logSearch'
              onChange={() => {
                // Implement search functionality
              }}
            /> */}
            </div>
          </div>
          {store.projectStore.getProjects().length !== 0 ? (
            <Table
              tableStyle={{
                borderColor: pallete.colors.stroke2
              }}
              data={store.projectStore.getProjects()}
              columns={columns}
              totalCount={store.projectStore.getTotalCount()}
              rowsPerPage={10}
              currentPage={store.projectStore.getCurrentPage()}
              onPageChange={(page: number) => {
                const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                  location.search
                )
                setSearchParams({ ...allQueryParams, page: page.toString() })
                setCurrentPage(page)
              }}
            />
          ) : (
            <NoRecord
              style={{ height: '70vh' }}
              imageSrc={
                store.filterStore.getFiltersApplied() ? NoFilterImage : ''
              }
              text={
                store.filterStore.getFiltersApplied()
                  ? StringConstants.NO_RECORDS_FILTER
                  : ''
              }
            />
          )}
          <div className='override-model-min-width'>
            <SettingAddPopup
              showHeader
              title='Create Project'
              modalOpen={createProjectPopupOpen}
              setModalOpen={setCreateProjectPopupOpen}
              primaryButtonText='Create Project'
              primaryButtonDisabled={
                store.projectStore.getLoading() ||
                projectName === '' ||
                projectType === '' ||
                checkLoading
              }
              secondaryButtonDisabled={
                store.projectStore.getLoading() || checkLoading
              }
              secondaryButtonText='Cancel'
              primaryButtonClick={checkAvailability}
              secondaryButtonClick={() => {
                setCreateProjectPopupOpen(false)
                setProjectName('')
                setProjectType('')
              }}
              loading={store.projectStore.getLoading() || checkLoading}
              showFooter={true}
              content={
                <div className='flex flex-col p-[24px] gap-[24px]'>
                  <div>
                    <DDSTypography.Title
                      variant='semiBold'
                      type='h4'
                      color={pallete.colors.textDark3}
                    >
                      {SettingConstants.MANAGE_APPLICATION}
                    </DDSTypography.Title>
                  </div>
                  <Input
                    width='100%'
                    onChange={(e) => {
                      const projectName = e.target.value
                      setProjectName(projectName)
                    }}
                    labelPosition='top'
                    maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
                    label={
                      <DDSTypography.Paragraph
                        size='para'
                        variant='medium'
                        color={pallete.colors.textDark3}
                      >
                        {SettingConstants.PROJECT_NAME}
                      </DDSTypography.Paragraph>
                    }
                    id='input'
                    name='Input'
                    placeholder='Enter Project Name'
                    required={true}
                    validateInput={true}
                    validate={(value: string) => {
                      if (isNameAvailable === true) {
                        return {
                          error: false,
                          message: ''
                        }
                      } else if (isNameAvailable === false) {
                        return {
                          error: false,
                          message: 'Project Name already exists'
                        }
                      }
                      return {
                        error: false,
                        message: ''
                      }
                    }}
                  />
                  {userStore.getSelectedOrganization().type ===
                    OrganizationType.HYBRID && (
                    <Select
                      id='project-type'
                      required={true}
                      variant='top'
                      size='large'
                      name='Looking to Monitor'
                      label='Looking to Monitor'
                      options={ProjectTypeDropdownOptions}
                      searchable={false}
                      placeholder='Select Type'
                      onSelect={(value) => {
                        setProjectType(value)
                      }}
                    />
                  )}
                  {userStore.getSelectedOrganization().type ===
                    OrganizationType.SALESFORCE_ECOSYSTEM_ONLY && (
                    <Select
                      id='project-type'
                      required={true}
                      variant='top'
                      size='large'
                      name='Looking to Monitor'
                      label='Looking to Monitor'
                      options={SFecosystemProjectDropdownOptions}
                      searchable={false}
                      placeholder='Select Type'
                      onSelect={(value) => {
                        setProjectType(value)
                      }}
                    />
                  )}
                  {userStore.getSelectedOrganization().type ===
                    OrganizationType.FULL_STACK_ONLY && (
                    <Select
                      id='project-type'
                      variant='top'
                      required={true}
                      size='large'
                      name='Looking to Monitor'
                      label='Looking to Monitor'
                      options={fullStackProjectDropdownOptions}
                      searchable={false}
                      placeholder='Select Type'
                      onSelect={(value) => {
                        setProjectType(value)
                      }}
                    />
                  )}
                </div>
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export default observer(ProjectSettings)
