/* eslint-disable quotes */
export default {
  LOGIN_TITLE: 'Log in to your account',
  SIGNUP_TITLE: "Let's get you setup",
  LOGIN_ONBOARD: 'Onboard and Manage your Applications',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  FORGOT_PASSWORD: 'Forgot Password?',
  LOGIN: 'Sign in',
  GET_STARTED: 'Get Started',
  OR: 'or',
  LOGIN_SF: 'Sign in with Salesforce',
  SIGNUP_SF: 'Sign up with Salesforce',
  ACKNOWLEDGE: 'By signing up, you are agreeing to our',
  TERMS_OF_SERVICE: 'Terms of Service',
  AND: '&',
  PRIVACY_POLICY: 'Privacy Policy',
  FIRST_TIME: "Don't have an account?",
  ALREADY_HAVE_ACC: 'Already have an account?',
  CREATE_ACCOUNT: 'Sign up',
  WELCOME_BACK: 'Welcome Back!',
  SIGNUP_FREE: 'Create your account for free',
  NO_CREDIT_CARD: '(No credit card required)',
  MONITOR_MANAGE: 'Monitor & Manage',
  SALESFORCE_ORGS: 'Salesforce Orgs',
  MULESOFT_APPS: 'Mulesoft Applications',
  FULL_STACK_APPS: 'Full-stack Applications',
  BOTH: 'Both',
  ACCESS_EVERYTHING: 'Access to Everything',
  EXCEPTIONS: 'Exceptions',
  JOBS: 'Jobs',
  AUDIT: 'Audit',
  UPTIME: 'Uptime',
  ORGANIZATION: 'Organization Name',
  ORGANIZATION_TYPE: 'What are you looking to monitor?'
}
