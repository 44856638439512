import React, { useEffect, useState } from 'react'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../utils/UrlUtils'
import { isEmpty, isNull, isUndefined } from 'lodash'
import { useParams, useSearchParams } from 'react-router-dom'

import ApiGroupingStringConstants from '../../constants/ApiGroupingStringConstants'
import ContextSwitcherTabs from '../common/DDS/ContextSwitcherTab/ContextSwitcherTab'
import { DDSTypography } from 'den-design-system'
import NoData from '../NoData/NoData'
import NoRecord from '../common/NoContent/NoRecord'
import NoRecordImage from '../../assets/images/folder-not-found 1.svg'
import Spinner from '../common/Spinner/Spinner'
import Table from '../common/DDS/Table/Table'
import { observer } from 'mobx-react-lite'
import { paginate } from '../../utils/CommonUtils'
import pallete from '../../global/newPallete'
import routeConstants from '../../constants/RouteConstants'
import { useStoreContext } from '../../store/StoreContext'

const ViewAttributes: React.FC = () => {
  const { id } = useParams()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [tabIndex, setTabIndex] = useState<string>('1')
  const [searchParams, setSearchParams] = useSearchParams()
  const [tableHeaderData, setTableHeaderData] = useState<any>([])
  const [tableParamsData, setTableParamsData] = useState<any>([])
  const [tableQueryData, setTableQueryData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const store = useStoreContext()
  const apiDetails = store.apiDetailsStore.getApiData()

  const columns = [
    {
      columnHeaderStyle: { width: '50%' },
      columnDataStyle: { width: '50%' },
      heading: (
        <div>
          <DDSTypography.Paragraph
            size='para'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.ATTRIBUTE}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'attribute',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '50%' },
      columnDataStyle: { width: '50%' },
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='para'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.VALUE}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'value',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    }
  ]

  const getHeaders = () => {
    const headers = { ...store.apiDetailsStore.getApiData().headers }
    store.uiStore.setGlobalLoader(true)
    const headersAsArray = Object.entries(headers).map(
      ([key, value], index) => {
        return {
          attribute: key,
          value: value
        }
      }
    )
    setTableHeaderData(headersAsArray)
    setLoading(false)
    store.uiStore.setGlobalLoader(false)
  }

  const getParams = () => {
    const params = { ...store.apiDetailsStore.getApiData().params }
    store.uiStore.setGlobalLoader(true)
    const paramsAsArray = Object.entries(params).map(([key, value], index) => {
      return {
        attribute: key,
        value: value
      }
    })
    setTableParamsData(paramsAsArray)
    setLoading(false)
    store.uiStore.setGlobalLoader(false)
  }

  const getQuery = () => {
    const query = { ...store.apiDetailsStore.getApiData().query }
    store.uiStore.setGlobalLoader(true)
    const queryAsArray = Object.entries(query).map(([key, value], index) => {
      return {
        attribute: key,
        value: value
      }
    })
    setTableQueryData(queryAsArray)
    setLoading(false)
    store.uiStore.setGlobalLoader(false)
  }

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
    } else {
      setCurrentPage(1)
    }
    store.uiStore.setGlobalLoader(true)
    setLoading(true)

    getHeaders()
    getParams()
    getQuery()
  }, [store.apiDetailsStore.getApiData()])

  useEffect(() => {
    setLoading(store.apiDetailsStore.getAPILoading())
  }, [store.apiDetailsStore.apiLoading, currentPage])

  useEffect(() => {
    const tabFromParams = searchParams.get('tab')
    const tabFromUserParam = searchParams.get('userTab')
    const tabFromAllUserParam = searchParams.get('allUserTab')
    const apiData = store.apiDetailsStore.getApiData()
    const userName = localStorage.getItem('apiUserName')

    if (isUndefined(apiData?.urlTemplate)) return

    const apiMonitoringLink = replaceUrl(
      routeConstants.API_MONITORING,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    )

    const firstBreadcrumb = {
      label:
        tabFromParams === '1' || tabFromUserParam === '1'
          ? ApiGroupingStringConstants.GROUPED_API
          : ApiGroupingStringConstants.ALL_API,
      link: `${apiMonitoringLink}${
        tabFromUserParam === '1'
          ? '?tab=1&contextSwitcherTab=2'
          : tabFromAllUserParam === '2'
            ? '?tab=2&contextSwitcherTab=2'
            : tabFromParams === '1'
              ? '?tab=1'
              : '?tab=2'
      }`
    }

    const breadcrumbOptions = [firstBreadcrumb]

    if (tabFromUserParam === '1') {
      if (userName) {
        breadcrumbOptions.push({
          label: userName,
          link:
          replaceUrl(
            routeConstants.GROUPED_USER_DETAILS,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ).replace(':id', apiDetails.apiUserId as string) + '?tab=1'
        })
      }
    }

    breadcrumbOptions.push(
      {
        label: apiData?.urlTemplate as string,
        link:  tabFromUserParam || tabFromAllUserParam ? replaceUrl(
          routeConstants.GROUPED_USER_API_LOGS_DETAILS,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        ).replace(':apiGroupId/:apiUserId', `${apiDetails.apiGroupId}/${apiDetails.apiUserId}` as string) + '?tab=1'
        : replaceUrl(
          routeConstants.VIEW_GROUPED_API,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        ).replace(':id', apiDetails.apiGroupId as string) + '?tab=1'
      },
      {
        label: ApiGroupingStringConstants.ATTRIBUTES,
        link: '/'
      }
    )
    
    store.breadcrumbStore.setMultipleBreadcrumbsOptions(breadcrumbOptions)

    return () => {
      store.breadcrumbStore.reset()
    }
  }, [
    searchParams,
    store.apiDetailsStore.getApiData(),
    store.breadcrumbStore,
    store.apiDetailsStore.getUserName()
  ])

  useEffect(() => {
    const tabFromParams = searchParams.get('contextSwitcherTab')
    if (tabFromParams) {
      setTabIndex(tabFromParams.toString())
    }
  }, [])

  const paginatedHederData = paginate(tableHeaderData, currentPage, 10)
  const paginatedParamsData = paginate(tableParamsData, currentPage, 10)
  const paginateQueryData = paginate(tableQueryData, currentPage, 10)

  const ContextSwitcherTab = [
    {
      id: '1',
      label: (
        <div className='w-[88px] px-[16px]'>
          {ApiGroupingStringConstants.HEADERS}
        </div>
      ),
      children: (
        <div>
          {loading ? (
            <Spinner />
          ) : tableHeaderData?.length === 0 ? (
            <NoRecord imageSrc={NoRecordImage} text={'No records found'} />
          ) : (
            <div>
              <Table
                data={paginatedHederData}
                loading={loading}
                columns={columns}
                totalCount={tableHeaderData?.length}
                rowsPerPage={10}
                currentPage={currentPage}
                tableStyle={{
                  borderColor: pallete.colors.stroke2
                }}
                onPageChange={(page: number) => {
                  const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  setSearchParams({ ...allQueryParams, page: page.toString() })
                  setCurrentPage(page)
                }}
                className={`!border-[${pallete.colors.stroke2}] `}
              />
            </div>
          )}
        </div>
      )
    },
    {
      id: '2',
      label: (
        <div className='w-[82px] px-[16px]'>
          {ApiGroupingStringConstants.PARAMS}
        </div>
      ),
      children: (
        <div>
          {loading ? (
            <Spinner />
          ) : tableParamsData?.length === 0 ? (
            <NoRecord imageSrc={NoRecordImage} text={'No records found'} />
          ) : (
            <div>
              <Table
                data={paginatedParamsData}
                columns={columns}
                totalCount={tableParamsData?.length}
                rowsPerPage={10}
                currentPage={currentPage}
                tableStyle={{
                  borderColor: pallete.colors.stroke2
                }}
                onPageChange={(page: number) => {
                  const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  setSearchParams({ ...allQueryParams, page: page.toString() })
                  setCurrentPage(page)
                }}
                className={`!border-[${pallete.colors.stroke2}] `}
                loading={loading}
              />
            </div>
          )}
        </div>
      )
    },
    {
      id: '3',
      label: (
        <div className='w-[72px] px-[16px]'>
          {ApiGroupingStringConstants.QUERY}
        </div>
      ),
      children: (
        <div>
          {loading ? (
            <Spinner />
          ) : tableQueryData?.length === 0 ? (
            <NoRecord imageSrc={NoRecordImage} text={'No records found'} />
          ) : (
            <div>
              <Table
                data={paginateQueryData}
                columns={columns}
                totalCount={tableQueryData?.length}
                rowsPerPage={10}
                currentPage={currentPage}
                loading={loading}
                tableStyle={{
                  borderColor: pallete.colors.stroke2
                }}
                onPageChange={(page: number) => {
                  const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  setSearchParams({ ...allQueryParams, page: page.toString() })
                  setCurrentPage(page)
                }}
                className={`!border-[${pallete.colors.stroke2}] `}
              />
            </div>
          )}
        </div>
      )
    }
  ]

  return (
    <div>
      <div className='flex h-full w-full'>
        <div className='py-[24px] w-full '>
          <ContextSwitcherTabs
            tabOptions={ContextSwitcherTab}
            shape='roundedRectangle'
            tabStyle={{
              border: '1px solid',
              borderColor: pallete.colors.stroke2,
              userSelect: 'none',
              width: '251px'
            }}
            tabTitleClassName='w-[88px] text-center select-none'
            activeTab={tabIndex}
            onChange={(index: any) => {
              setTabIndex(index.toString())
              const queryParams = getAllQueryParamsAsObjectFromUrl(
                location.search
              )
              setCurrentPage(1)
              setSearchParams({
                ...queryParams,
                page: '1',
                contextSwitcherTab: index.toString()
              })
            }}
            className='override-height'
          />
        </div>
      </div>
    </div>
  )
}

export default observer(ViewAttributes)
