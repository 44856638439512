import {
  AtoB,
  BtoA,
  UrlWithOnlyOrgId,
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import React, { useEffect, useState } from 'react'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import {
  default as palette,
  default as pallete
} from '../../../global/newPallete'

import ContextSwitcherTabs from '../../common/DDS/ContextSwitcherTab/ContextSwitcherTab'
import { DDSTypography } from 'den-design-system'
import { EventBus } from '../../../eventBus/EventBus'
import FullstackIcon from '../../../assets/icons/fullstack.svg'
import HttpConstants from '../../../constants/HttpConstants'
import MulesoftIcon from '../../../assets/icons/newMulesoft.svg'
import { OrganizationType } from '../../../enum/OrganizationType.enum'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import { ProjectType } from '../../../enum/ProjectType.enum'
import SalesforceIcon from '../../../assets/icons/newSalesforce.svg'
import Select from '../Select/Select'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import { getOrganizationProjectsThingsToDo } from '../../../api/organization/Organization.service'
import { getProjectAndEnvironment } from '../../../api/project/Project.service'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import routeConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'
import vigilIcon from '../../../assets/icons/vigilSmallLogo.svg'

interface SelectSectionProps {
  icon: JSX.Element
  title: string
  isSidebarExpanded: boolean
}

const IconContainer = styled.div`
  order: 0;
`
const TopBarSelectSection: React.FC<SelectSectionProps> = ({
  icon,
  title,
  isSidebarExpanded
}) => {
  const store = useStoreContext()
  const { pathIds } = useParams()
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.TOP_NAVBAR)
  const navigate = useNavigate()
  const isNoProject =
    store.userStore.getSelectedOrganization().projectCount === 0

  const location = useLocation()
  const currentPath = location.pathname

  useEffect(() => {
    const environments = store.scopeStore.getEnvironments()

    if (
      !currentPath.includes('settings') &&
      !currentPath.includes('allNotifications') &&
      title !== 'Invitations' &&
      title !== 'Notifications' &&
      title !== 'Project Settings' &&
      title !== 'Account Settings' &&
      title !== 'Organization Settings'
    ) {
      const matchingEnvironment = environments.filter(
        (env) => AtoB(pathIds as string).split('/')[2] === env.id
      )

      if (!isEmpty(environments)) {
        if (
          matchingEnvironment[0]?.type ===
          StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
        ) {
          localStorage.setItem(
            StringConstants.ENVIRONMENT_TYPE,
            StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
          )
          handleEnvironmentChange(environments[0].id)
        } else {
          localStorage.setItem(
            StringConstants.ENVIRONMENT_TYPE,
            StringConstants.ENVIRONMENT_TYPE_VALUES.PROD
          )
          handleEnvironmentChange(environments[1].id)
        }
      }
    }
  }, [store.scopeStore.getEnvironments()])

  useEffect(() => {
    const environments = store.scopeStore.getEnvironments()
    if (
      !currentPath.includes('settings') &&
      !currentPath.includes('allNotifications') &&
      title !== 'Invitations' &&
      title !== 'Notifications' &&
      title !== 'Project Settings' &&
      title !== 'Account Settings' &&
      title !== 'Organization Settings'
    ) {
      const matchingEnvironment = environments.filter(
        (env) => AtoB(pathIds as string).split('/')[2] === env.id
      )

      if (!isEmpty(environments)) {
        if (
          matchingEnvironment[0]?.type ===
          StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
        ) {
          localStorage.setItem(
            StringConstants.ENVIRONMENT_TYPE,
            StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
          )
          handleEnvironmentChange(environments[0].id)
        } else {
          localStorage.setItem(
            StringConstants.ENVIRONMENT_TYPE,
            StringConstants.ENVIRONMENT_TYPE_VALUES.PROD
          )
          handleEnvironmentChange(environments[1].id)
        }
      }
    }
  }, [store.scopeStore.getEnvironments()])

  useEffect(() => {
    if (
      store.userStore.getUserRole() !== OrganizationUserRoles.BILLING &&
      !window.location.pathname.split('/').includes('preferences') &&
      !window.location.pathname.split('/').includes('organization') &&
      !isEmpty(store.scopeStore.getOrganizationId())
    ) {
      store.uiStore.setprojectEnvironmentLoader(true)
      if (store.userStore.getOrganizations().length > 0) {
        getProjectAndEnvironment()
          .then((projectResponse: any) => {
            if (!projectResponse.data.length) {
              store.scopeStore.setProjectId('')
              store.scopeStore.setEnvironmentId('')
            } else {
              store.projectAndEnvironmentStore.setProjectsAndEnvironments(
                projectResponse.data
              )
              handleThingsToDo(projectResponse.data[0])
              store.scopeStore.setProjects(projectResponse.data)
              store.scopeStore.setEnvironments(
                projectResponse.data[0].environments
              )
              if (
                isEmpty(store.scopeStore.getProjectId()) ||
                isEmpty(store.scopeStore.getEnvironmentId())
              ) {
                store.scopeStore.setSelectedProject(projectResponse.data[0])
                handleThingsToDo(projectResponse.data[0])
                store.scopeStore.setSelectedEnvironment(
                  projectResponse.data[0].environments[1]
                )
                store.scopeStore.setProjectId(projectResponse.data[0].id)
                store.scopeStore.setEnvironmentId(
                  projectResponse.data[0].environments[1].id
                )
                localStorage.setItem(
                  StringConstants.ENVIRONMENT_TYPE,
                  StringConstants.ENVIRONMENT_TYPE_VALUES.PROD
                )

                if (
                  !window.location.pathname.split('/').includes('settings') &&
                  !window.location.pathname.split('/').includes('allInvites') &&
                  !window.location.pathname
                    .split('/')
                    .includes('allNotifications') &&
                  !isEmpty(store.scopeStore.getOrganizationId())
                ) {
                  handleThingsToDo(projectResponse.data[0])
                  let url =
                    '/' +
                    BtoA(
                      store.scopeStore.getOrganizationId() +
                        '/' +
                        projectResponse.data[0].id +
                        '/' +
                        projectResponse.data[0].environments[1].id
                    ) +
                    '/' +
                    window.location.pathname.split('/')[2]

                  if (window.location.pathname.split('/')[3] !== undefined) {
                    url += '/' + window.location.pathname.split('/')[3]
                  }

                  navigate(url)
                }
              } else {
                const project = projectResponse.data.filter((project: any) => {
                  return project.id === store.scopeStore.getProjectId()
                })[0]
                if (project) {
                  handleThingsToDo(projectResponse.data[0])
                  const selectedEnvironment = project.environments.filter(
                    (env: any) => {
                      return env.id === store.scopeStore.getEnvironmentId()
                    }
                  )[0]

                  store.scopeStore.setSelectedProject(project)
                  handleThingsToDo(project)
                  store.scopeStore.setEnvironments(project.environments)
                  if (selectedEnvironment) {
                    handleThingsToDo(projectResponse.data[0])
                    store.scopeStore.setSelectedEnvironment(selectedEnvironment)
                  } else {
                    store.scopeStore.setSelectedEnvironment(
                      project.environments[0]
                    )
                    store.scopeStore.setEnvironmentId(
                      project.environments[0]?.id
                    )
                  }
                } else {
                  store.scopeStore.setSelectedProject(projectResponse.data[0])
                  handleThingsToDo(projectResponse.data[0])
                  store.scopeStore.setSelectedEnvironment(
                    projectResponse.data[0].environments[0]
                  )
                  store.scopeStore.setProjectId(projectResponse.data[0].id)
                  store.scopeStore.setEnvironmentId(
                    projectResponse.data[0].environments[0].id
                  )
                }
              }
            }
          })
          .catch((err) => {
            if (err === 'Unauthorized') {
              navigate(routeConstants.UNAUTHORIZED_PAGE)
            }
          })
          .finally(() => {
            store.uiStore.setprojectEnvironmentLoader(false)
          })
      } else {
        store.uiStore.setprojectEnvironmentLoader(false)
      }
    } else {
      store.uiStore.setprojectEnvironmentLoader(false)
    }
  }, [store.scopeStore.getOrganizationId()])

  const navigateToOverview = () => {
    if (window.location.href.includes('things-to-do')) {
      navigate(
        replaceUrl(
          routeConstants.OVERVIEW,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )
      )
      localStorage.setItem(
        StringConstants.ACTIVE_TAB,
        StringConstants.TAB_VALUES.OVERVIEW
      )
    }
  }

  const handleThingsToDo = (project: any) => {
    if (
      project &&
      (project.type == ProjectType.SALESFORCE_ONLY ||
        project.type == ProjectType.MULESOFT_ONLY) &&
      Number(project.thingsToDo) ==
        StringConstants.THINGS_TO_DO.SALESFORCE_ECOSYSTEM_PROJECT_PROGRESS_VALUE
    ) {
      store.thingsToDoStore.setShowThingsToDo(false)
      navigateToOverview()
    } else if (
      project &&
      project.type == ProjectType.FULL_STACK_ONLY &&
      Number(project.thingsToDo) ==
        StringConstants.THINGS_TO_DO.FULLSTACK_PROJECT_PROGRESS_VALUE
    ) {
      store.thingsToDoStore.setShowThingsToDo(false)
      navigateToOverview()
    } else if (
      store.userStore.getUserRole() === OrganizationUserRoles.BILLING
    ) {
      store.thingsToDoStore.setShowThingsToDo(false)
    } else {
      if (
        store.scopeStore.getScope()['organization-id']?.length &&
        store.scopeStore.getScope()['project-id']?.length
      ) {
        getOrganizationProjectsThingsToDo()
          .then((response: any) => {
            store.thingsToDoStore.setProgressData(response.data)
            if (
              (response?.data?.progress == 3 &&
                (project.type == ProjectType.SALESFORCE_ONLY ||
                  project.type == ProjectType.MULESOFT_ONLY)) ||
              (response?.data?.progress == 5 &&
                project.type == ProjectType.FULL_STACK_ONLY)
            ) {
              store.thingsToDoStore.setShowThingsToDo(false)
            } else {
              store.thingsToDoStore.setShowThingsToDo(true)
            }
          })
          .catch((error) => console.log(error))
          .finally(() => {
            store.uiStore.setGlobalLoader(false)
          })
      }
      if (
        project &&
        (project.type == ProjectType.SALESFORCE_ONLY ||
          project.type == ProjectType.MULESOFT_ONLY)
      ) {
        store.thingsToDoStore.setTotalProgressCount(3)
      } else {
        store.thingsToDoStore.setTotalProgressCount(5)
      }
    }
  }

  const getProjectsWithType = () => {
    const projects = store.scopeStore.getProjects()
    projects.forEach((p) => {
      if (p.type === ProjectType.FULL_STACK_ONLY)
        p.icon = (
          <img src={FullstackIcon} sizes='16px' width='16px' height='16px' />
        )
      if (p.type === ProjectType.SALESFORCE_ONLY)
        p.icon = (
          <img src={SalesforceIcon} sizes='16px' width='16px' height='16px' />
        )
      if (p.type === ProjectType.MULESOFT_ONLY)
        p.icon = (
          <img src={MulesoftIcon} sizes='16px' width='16px' height='16px' />
        )
    })
    return projects.map((project) => ({
      label: (
        <div className='flex flex-row  items-center height-[25px] gap-x-[2px]'>
          <div
            className='flex items-center'
            style={{ maxWidth: project.name.length > 20 ? '142px' : '' }}
            title={project.name.length > 20 ? project.name : ''}
          >
            <DDSTypography.Paragraph
              variant='medium'
              size='caption'
              className='block truncate'
              color={palette.colors.textDark4}
            >
              {project.name}
            </DDSTypography.Paragraph>
          </div>

          {(project.type === ProjectType.SALESFORCE_ONLY ||
            project.type === ProjectType.MULESOFT_ONLY ||
            project.type === ProjectType.FULL_STACK_ONLY) &&
            project.icon}
        </div>
      ),
      value: project.id
    }))
  }

  const getEnvironments = () => {
    const environments = store.scopeStore.getEnvironments()
    return environments.map((env) => ({
      label: (
        <DDSTypography.Paragraph
          variant='medium'
          size='para'
          className='truncate flex items-start'
          color={
            env.type == localStorage.getItem(StringConstants.ENVIRONMENT_TYPE)
              ? pallete.colors.textDark1
              : palette.colors.textDark5
          }
        >
          {env.type == StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
            ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
            : StringConstants.ENVIRONMENT_NAMES.PROD}
        </DDSTypography.Paragraph>
      ),
      value: env.id
    }))
  }

  const handleProjectChange = (id: string) => {
    store.filterStore.setFiltersApplied(false)
    if (id === store.scopeStore.getSelectedProject().id) {
      return
    }
    store.uiStore.setGlobalLoader(true)
    store.scopeStore.setProjectId(id)
    if (id !== store.scopeStore.getSelectedProject().id) {
      gaEventTracker(gaEventConstants.SWITCH_PROJECT)
    }
    const project = store.scopeStore
      .getProjects()
      .filter((record: any) => record.id === id)
    store.scopeStore.setSelectedProject(project[0])
    store.projectAndEnvironmentStore.setSelectedProject(project[0])
    if (project && project.length > 0) {
      handleThingsToDo(project[0])
      store.scopeStore.setEnvironments(project[0].environments)
    }
    if (
      localStorage.getItem(StringConstants.ENVIRONMENT_TYPE) ===
      StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
    )
      handleEnvironmentChange(project[0].environments[0]?.id)
    else handleEnvironmentChange(project[0].environments[1]?.id)

    if (
      (window.location.pathname.includes('apiMonitoring') &&
        (store.scopeStore.getSelectedProject().type ===
          ProjectType.SALESFORCE_ONLY ||
          store.scopeStore.getSelectedProject().type ===
            ProjectType.MULESOFT_ONLY)) ||
      (window.location.pathname.includes('salesforce-audit') &&
        (store.scopeStore.getSelectedProject().type ===
          ProjectType.FULL_STACK_ONLY ||
          store.scopeStore.getSelectedProject().type ===
            ProjectType.MULESOFT_ONLY)) ||
      (window.location.pathname.includes('jobMonitoring') &&
        (store.scopeStore.getSelectedProject().type ===
          ProjectType.MULESOFT_ONLY ||
          store.scopeStore.getSelectedProject().type ===
            ProjectType.SALESFORCE_ONLY))
    ) {
      localStorage.setItem(
        StringConstants.ACTIVE_TAB,
        StringConstants.TAB_VALUES.OVERVIEW
      )
      navigate(
        replaceUrl(
          routeConstants.OVERVIEW,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )
      )
    }
    store.breadcrumbStore.reset()
  }

  const getEventForRoute = () => {
    if (
      window.location.pathname.split('/')[2] ===
      routeConstants.JOB_MONITORING.split('/')[1]
    ) {
      EventBus.getInstance().dispatch<string>('get-Jobs')
    }
  }

  const handleEnvironmentChange = (id: string) => {
    if (id === store.scopeStore.getSelectedEnvironment().id) {
      return
    }
    store.uiStore.setGlobalLoader(true)
    store.scopeStore.setEnvironmentId(id)
    if (id !== store.scopeStore.getSelectedEnvironment().id) {
      gaEventTracker(gaEventConstants.SWITCH_ENVIRONMENT)
    }
    const environment = store.scopeStore
      .getEnvironments()
      .filter((record: any) => record.id == id)
    store.scopeStore.setSelectedEnvironment(environment[0])
    store.filterStore.setFiltersApplied(false)
    getEventForRoute()
    let url = BtoA(
      store.scopeStore.getOrganizationId() +
        '/' +
        store.scopeStore.getScope()['project-id'] +
        '/' +
        id
    )
    url += '/' + window.location.pathname.split('/')[2]
    if (
      ['dashboard', 'report'].includes(window.location.pathname.split('/')[3])
    ) {
      url += '/' + window.location.pathname.split('/')[3]
    }
    const pathName = '../' + '../' + url

    navigate({
      pathname: pathName
    })
    store.breadcrumbStore.reset()
  }

  return (
    <div className='flex flex-row w-full items-center justify-between'>
      <div className='flex flex-row items-center'>
        {store.userStore.getOrganizations().length === 0 && (
          <IconContainer>
            <img src={vigilIcon} alt='logo' />
          </IconContainer>
        )}
        <span color={palette.colors.primary2}>{icon}</span>
        <div
          className={`ml-[8px] flex flex-col xl:pr-[44px] lg:pr-[22px] pr-[11px] flex-wrap`}
        >
          <DDSTypography.Title
            type='h5'
            variant='bold'
            style={{
              color: palette.colors.textDark2
            }}
          >
            {title}
          </DDSTypography.Title>
        </div>
      </div>
      <div className='mr-[24px]'>
        {title !== 'Project Settings' &&
          title !== 'Account Settings' &&
          title !== 'Organization Settings' &&
          title !== 'Notifications' &&
          title !== 'Invitations' &&
          store.userStore.getOrganizations().length > 0 &&
          store.userStore.getUserRole() !== OrganizationUserRoles.BILLING &&
          !isNoProject && (
            <div className='flex xl:gap-[24px] gap-[10px] items-center justify-center override-project-select'>
              <Select
                id='projects'
                name='projects'
                options={getProjectsWithType()}
                value={
                  store.uiStore.getGlobalLoader() &&
                  !store.scopeStore.getSelectedProject()?.id
                    ? 'Loading...'
                    : store.scopeStore.getSelectedProject().id
                }
                searchable={false}
                disabled={store.uiStore.getGlobalLoader()}
                placeholder='Choose'
                onSelect={(value) => {
                  handleProjectChange(value)
                }}
              />
              {title !==
                StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS
                  .GET_STARTED && (
                <ContextSwitcherTabs
                  activeTab={
                    localStorage.getItem(StringConstants.ENVIRONMENT_TYPE) ==
                    StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
                      ? '1'
                      : '2'
                  }
                  tabOptions={getEnvironments()}
                  tabTitleStyles={{
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '16px',
                    paddingRight: '16px'
                  }}
                  shape='roundedRectangle'
                  tabStyle={{
                    border: '1px solid',
                    borderColor: pallete.colors.stroke2,
                    userSelect: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    width: '191px',
                    height: '32px'
                  }}
                  onChange={(index: any) => {
                    const environments = store.scopeStore.getEnvironments()
                    if (index == 1) {
                      localStorage.setItem(
                        StringConstants.ENVIRONMENT_TYPE,
                        StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
                      )
                      handleEnvironmentChange(environments[0].id)
                    } else {
                      localStorage.setItem(
                        StringConstants.ENVIRONMENT_TYPE,
                        StringConstants.ENVIRONMENT_TYPE_VALUES.PROD
                      )
                      handleEnvironmentChange(environments[1].id)
                    }
                  }}
                  disabled={store.uiStore.getGlobalLoader()}
                  tabTitleClassName='w-[88px] text-center select-none'
                  className={`pt-[16px]  ${
                    localStorage.getItem(StringConstants.ENVIRONMENT_TYPE) ===
                    StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
                      ? 'override-on-non-prod'
                      : ''
                  }`}
                />
              )}
            </div>
          )}
      </div>
    </div>
  )
}

export default observer(TopBarSelectSection)
