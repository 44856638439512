import { Renew, TrashCan } from '@carbon/icons-react'

import { Button } from '../../../../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import { OrganizationUserRoles } from '../../../../../../enum/OrganizationUserRoles.enum'
import React from 'react'
import Spinner from '../../../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../../../constants/StringConstants'
import Table from '../../../../../../components/common/DDS/Table/Table'
import Tooltip from '../../../../../../components/New/Tooltip/Tooltip'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../../../global/newPallete'
import { timeFormats } from '../../../../../../enum/TIME'
import { timeZone } from '../../../../../../utils/DateTimeUtils'
import { useStoreContext } from '../../../../../../store/StoreContext'

const InvitedMember: React.FC<{
  data: []
  invitedUser?: boolean
  rowsPerPage?: number
  currentPage?: number
  loading?: boolean
  onPageChange?: (page: number) => void
  totalCount?: number
  handleConfirmRevokeInvite?: (member: any) => void
  handleRemoveMember?: (member: any) => void
  handleResendInvite?: (member: any) => void
  fetchMembers: any
  updateMembersRole?: (flag: boolean) => void
}> = ({
  data,
  loading,
  handleConfirmRevokeInvite = () => null,
  handleRemoveMember = () => null,
  handleResendInvite = () => null,
  updateMembersRole = () => null,
  invitedUser,
  rowsPerPage = StringConstants.DEFAULT_TABLE_SIZE,
  currentPage = 0,
  onPageChange = () => null,
  totalCount = 0,
  fetchMembers
}) => {
  const store = useStoreContext()

  const column = [
    {
      columnHeaderStyle: { width: '200px' },
      columnDataStyle: { width: '200px' },
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Inviter
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'inviter',
      render: (text: any) => (
        <Tooltip
          id={`tooltip-name-${text?.firstName + ' ' + text?.lastName}`}
          label={text?.firstName + ' ' + text?.lastName}
          className={`${
            typeof text?.firstName === 'string' &&
            typeof text?.lastName === 'string' &&
            text?.firstName.length + text?.lastName.length > 20
              ? text?.firstName + ' ' + text?.lastName
              : '!hidden'
          }`}
        >
          <div className='flex justify-start text-start row items-start'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate max-w-[200px]'
            >
              {text?.firstName + ' ' + text?.lastName}
            </DDSTypography.Paragraph>
          </div>
        </Tooltip>
      )
    },

    {
      columnHeaderStyle: { width: '160px' },
      columnDataStyle: { width: '160px' },
      heading: (
        <div className='flex justify-center text-center row items-center max-w-[160px]'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Invitee Email
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'invitee',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center max-w-[160px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text ? text : '-'}
              className={`${
                typeof text === 'string' && text.length > 10 ? text : '!hidden'
              }`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                color={pallete.colors.textDark4}
                className='tooltip-container sm:block overflow-hidden truncate max-w-[160px]'
              >
                {text}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        </div>
      )
    },

    {
      columnHeaderStyle: { width: '140px' },
      columnDataStyle: { width: '140px' },
      heading: (
        <div className='flex justify-center text-center row items-center max-w-[140px]'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Role
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'role',
      render: (fullItem: any) => (
        <div className='flex justify-center text-center row items-center max-w-[140px]'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden'
          >
            {fullItem}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '160px' },
      columnDataStyle: { width: '160px' },
      heading: (
        <div className='flex justify-center text-center row items-center max-w-[160px]'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Invited on
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'createdAt',
      render: (text: any) => {
        return (
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate max-w-[160px]'
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTime
              )}
            </DDSTypography.Paragraph>
          </div>
        )
      }
    }
  ]

  const ownerColumn = [
    {
      columnHeaderStyle: { width: '200px' },
      columnDataStyle: { width: '200px' },
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Inviter
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'inviter',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start'>
          <div>
            <Tooltip
              id={`tooltip-name-${text?.firstName + ' ' + text?.lastName}`}
              label={text?.firstName + ' ' + text?.lastName}
              className={`${
                typeof text?.firstName === 'string' &&
                typeof text?.lastName === 'string' &&
                text?.firstName.length + text?.lastName.length > 20
                  ? text?.firstName + ' ' + text?.lastName
                  : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[200px]'
                >
                  {text?.firstName + ' ' + text?.lastName}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },

    {
      columnHeaderStyle: { width: '160px' },
      columnDataStyle: { width: '160px' },
      heading: (
        <div className='flex justify-center text-center row items-center max-w-[160px]'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Invitee Email
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'invitee',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center max-w-[160px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text ? text : '-'}
              className={`${
                typeof text === 'string' && text.length > 15 ? text : '!hidden'
              }`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                color={pallete.colors.textDark4}
                className='tooltip-container sm:block overflow-hidden truncate max-w-[160px]'
              >
                {text}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        </div>
      )
    },

    {
      columnHeaderStyle: { width: '140px' },
      columnDataStyle: { width: '140px' },
      heading: (
        <div className='flex justify-center text-center row items-center max-w-[140px]'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Role
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'role',
      render: (fullItem: any) => (
        <div className='flex justify-center text-center row items-center max-w-[140px]'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden'
          >
            {fullItem}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '160px' },
      columnDataStyle: { width: '160px' },
      heading: (
        <div className='flex justify-center text-center row items-center max-w-[160px]'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Invited on
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'createdAt',
      render: (text: any) => {
        return (
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate max-w-[160px]'
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTime
              )}
            </DDSTypography.Paragraph>
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center cursor-pointer'>
          {invitedUser && (
            <Button
              id='right-btn'
              type='outline'
              size='small'
              startIcon={<Renew size={16} color={pallete.colors.textDark2} />}
              label='Resend Invite'
              onClick={() => {
                handleResendInvite(fullItem)
              }}
            />
          )}
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center cursor-pointer'>
          <Button
            id='right-btn'
            type='danger'
            size='small'
            startIcon={
              <TrashCan size={16} color={pallete.colors.warningRed2} />
            }
            label='Revoke Invite'
            onClick={() => {
              handleConfirmRevokeInvite(fullItem)
            }}
          />
        </div>
      )
    }
  ]

  return (
    <>
      {loading || store.uiStore.getGlobalLoader() ? (
        <Spinner />
      ) : (
        <div className='flex pt-[32px] w-full table-non-interactive-member'>
          <Table
            tableStyle={{
              borderColor: pallete.colors.stroke2
            }}
            data={data}
            columns={
              store.userStore.getUserRole() == OrganizationUserRoles.OWNER
                ? ownerColumn
                : column
            }
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </>
  )
}

export default observer(InvitedMember)
