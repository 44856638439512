import React, { useEffect, useState } from 'react'
import {
  downloadAllUrlView,
  getAPIFilters
} from '../../../api/apiMonitoring/ApiMonitoring.service'

import AllAPIViewURL from './Screen/AllAPIViewURL'
import AllAPIViewUser from './Screen/AllAPIViewUser'
import { Button } from '../../New/Button/Button'
import ContextSwitcherTabs from '../../common/DDS/ContextSwitcherTab/ContextSwitcherTab'
import { Download } from '@carbon/icons-react'
import Filter from '../../Filter/Filter'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import { convertJsonToCsvDownload } from '../../../utils/FileUtils'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const AllAPITab = () => {
  const store = useStoreContext()

  const [searchParams, setSearchParams] = useSearchParams()
  const [tabIndex, setTabIndex] = useState<string>(
    searchParams.get('contextSwitcherTab') ?? '1'
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<any>([])

  const allAPIUrlFilter = () => {
    getAPIFilters()
      .then((response: any) => {
        setOptions(response.data)
      })
      .catch((err) => {
        return ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
  }

  const allAPIUserFilter = () => {
    getAPIFilters()
      .then((response: any) => {
        const filteredOptions = response.data.filter(
          (item: any) => item.heading !== 'URL Template'
        )
        setOptions(filteredOptions)
      })
      .catch((err) => {
        return ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
  }

  useEffect(() => {
    if (tabIndex === '1') {
      allAPIUrlFilter()
    } else {
      allAPIUserFilter()
    }
  }, [tabIndex, store.scopeStore.getScope()])

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
  }, [])

  const downloadRecords = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParamsForDownload = {
      ...allQueryParams,
      'time-zone': store.userStore.getUserPreference().timeZone
    }
    downloadAllUrlView(queryParamsForDownload)
      .then((response: any) => {
        const date = new Date()
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`
        convertJsonToCsvDownload(response.data, `All_API_${formattedDate}.csv`)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: 'Error downloading log in all API URL view'
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const downloadRecordsNoUserName = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParamsForDownload = {
      ...allQueryParams,
      'time-zone': store.userStore.getUserPreference().timeZone
    }

    downloadAllUrlView(queryParamsForDownload)
      .then((response: any) => {
        const filteredData = response.data.map(
          ({
            apiUserName,
            apiUserId,
            ...rest
          }: {
            apiUserName: string
            apiUserId: string
            [key: string]: any
          }) => rest
        )

        const date = new Date()
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`
        convertJsonToCsvDownload(filteredData, `All_API_${formattedDate}.csv`)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: 'Error downloading log in all API URL view'
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  const ContextSwitcherTab = [
    {
      id: '1',
      label: 'URL View',
      children: (
        <div>
          <AllAPIViewURL
            loading={loading}
            setLoading={setLoading}
            allAPIFilter={allAPIUrlFilter}
          />
        </div>
      )
    },
    {
      id: '2',
      label: 'User View',
      children: (
        <div className='relative'>
          <AllAPIViewUser
            loading={loading}
            setLoading={setLoading}
            allAPIUserFilter={allAPIUserFilter}
          />
        </div>
      )
    }
  ]

  return (
    <>
      {foundOrganization.subscription.trackApiUsage === true ? (
        <div className='pt-[24px]'>
          <div className='flex items-center justify-between w-full'>
            <div className='flex-grow override-view-more-tabs'>
              <ContextSwitcherTabs
                tabOptions={ContextSwitcherTab}
                shape='roundedRectangle'
                tabStyle={{
                  border: '1px solid',
                  borderColor: pallete.colors.stroke2,
                  userSelect: 'none',
                  width: '204px'
                }}
                tabTitleClassName='text-center select-none'
                activeTab={tabIndex}
                onChange={(index: any) => {
                  setTabIndex(index)
                  setSearchParams({
                    page: '1',
                    tab: searchParams.get('tab') ?? '1',
                    contextSwitcherTab: index
                  })
                }}
                disabled={loading || store.uiStore.getGlobalLoader()}
                className='override-height override-view-more-tabs'
              />

              <div className='flex items-center custom-filter justify-end absolute right-0 top-[72px] space-x-[12px]'>
                <Filter
                  options={options}
                  isScrollable={true}
                  disabled={loading || store.uiStore.getGlobalLoader()}
                />

                <Button
                  id='outline-button'
                  label='Export'
                  disabled={loading || store.uiStore.getGlobalLoader()}
                  type='neutral'
                  size='small'
                  onClick={() => downloadRecords()}
                  startIcon={<Download color={pallete.colors.neuButtonText} />}
                />

                {/* <Search
              placeholder='Search by URL, HTTP Method...'
              id='log-search'
              name='logSearch'
              width='35vh'
              onChange={() => {
                // Implement search functionality
              }}
            /> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='pt-[24px]'>
          <div className='flex items-center justify-between w-full'>
            <div className='flex-grow override-view-more-tabs'></div>
          </div>
          <div
            className={`flex items-center custom-filter  justify-end  space-x-[12px] `}
          >
            <Filter
              options={options}
              isScrollable={true}
              disabled={loading || store.uiStore.getGlobalLoader()}
            />

            <Button
              id='outline-button'
              label='Export'
              disabled={loading || store.uiStore.getGlobalLoader()}
              type='neutral'
              size='small'
              onClick={() => downloadRecordsNoUserName()}
              startIcon={<Download color={pallete.colors.neuButtonText} />}
            />
          </div>

          <div>
            <AllAPIViewURL
              loading={loading}
              setLoading={setLoading}
              allAPIFilter={allAPIUrlFilter}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default observer(AllAPITab)
