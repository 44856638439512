import { DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import {
  fetchHeaderGroupedData,
  getAPILogFilters
} from '../../../api/apiMonitoring/ApiMonitoring.service'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { Button } from '../../../components/New/Button/Button'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import Filter from '../../../components/Filter/Filter'
import GroupedAPILogTable from './GroupedAPILogTable'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import { Renew } from '@carbon/icons-react'
import Spinner from '../../../components/common/Spinner/Spinner'
import StatusCodeGraph from '../../../components/common/StatusCodeGraph/StatusCodeGraph'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import { color } from '../../../utils/ApiStatusColorCode'
import { gaPageConstants } from '../../../constants/GAConstants'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { replaceUrl } from '../../../utils/UrlUtils'
import routeConstants from '../../../constants/RouteConstants'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

type ApiRecord = { [key: string]: string }

const ViewGroupedAPIDetails: React.FC = () => {
  const [headerData, setHeaderData] = useState<any>()

  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams()

  const [options, setOptions] = useState<any>([])
  const navigate = useNavigate()
  const { id } = useParams()
  const [tabIndex, setTabIndex] = React.useState<string>(
    searchParams.get('tab') ?? '1'
  )
  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.API_MONITORING_TABLE
  )
  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
  }, [])

  const fetchAPILogFilters = () => {
    store.filterStore.setFilterDisabled(true)
    getAPILogFilters({ $id: id as string })
      .then((response: any) => {
        const filteredOptions = response.data.filter(
          (item: any) =>
            item.heading !== 'URL Template' && item.heading !== 'HTTP Method'
        )
        setOptions(filteredOptions)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
  }

  useEffect(() => {
    fetchAPILogFilters()
  }, [
    store.scopeStore.getEnvironmentId(),
    store.scopeStore.getSelectedProject(),
    store.scopeStore.getSelectedEnvironment()
  ])

  const viewHeaderGroupedApiData = async () => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    fetchHeaderGroupedData({ $id: id as string })
      .then((response: any) => {
        setHeaderData(response)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  useEffect(() => {
    store.filterStore.setFiltersApplied(false)
  }, [store])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  useEffect(() => {
    viewHeaderGroupedApiData()
  }, [])

  useEffect(() => {
    if (!isUndefined(headerData?.urlTemplate)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: ApiGroupingStringConstants.GROUPED_API,
          link:
            replaceUrl(
              routeConstants.API_MONITORING,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ) + '?tab=1'
        },
        {
          label: headerData?.urlTemplate,
          link: routeConstants.VIEW_GROUPED_API.replace(':id', id as string)
        }
      ])
    }
  }, [store.breadcrumbStore.getBreadcrumbsOptions(), headerData])

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  const tabs = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            API Logs
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            API Logs
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <GroupedAPILogTable
            fetchAPILogFilters={fetchAPILogFilters}
            viewHeaderGroupedApiData={viewHeaderGroupedApiData}
          />
        </div>
      )
    }
    // {
    //   label:
    //     tabIndex === '2' ? (
    //       <DDSTypography.Title
    //         type='h5'
    //         variant='semiBold'
    //         color={pallete.colors.primary2}
    //       >
    //         API Overview
    //       </DDSTypography.Title>
    //     ) : (
    //       <DDSTypography.Title
    //         type='h5'
    //         variant='medium'
    //         color={pallete.colors.textDark5}
    //       >
    //         API Overview
    //       </DDSTypography.Title>
    //     ),
    //   children: (
    //     <div>
    //       <APIUsage />
    //     </div>
    //   )
    // }
  ]

  return (
    <>
      <DocumentHeader header={HeaderConstants.API_MONITORING} />
      {loading || !headerData ? (
        <Spinner />
      ) : (
        <div className='h-full w-full'>
          <div className='w-full py-[24px]'>
            <div className='relative'>
              <div className='flex items-center justify-between'>
                <div>
                  <div className='flex justify-start items-start'>
                    <DDSTooltip
                      id='overview-tooltip'
                      position='top'
                      shape='roundedRectangle'
                      label={
                        <DDSTypography.Paragraph
                          size='para'
                          variant='regular'
                          color={pallete.colors.textDark4}
                        >
                          {headerData?.urlTemplate}
                        </DDSTypography.Paragraph>
                      }
                      className={`override-tooltip-arrow ${
                        typeof headerData?.urlTemplate === 'string' &&
                        headerData?.urlTemplate.length > 40
                          ? headerData.urlTemplate
                          : '!hidden'
                      }`}
                      style={{
                        backgroundColor: pallete.colors.surface1,
                        zIndex: 999999
                      }}
                    >
                      <DDSTypography.Title
                        type='h3'
                        variant='semiBold'
                        color={pallete.colors.textDark3}
                        className='truncate w-[600px]'
                      >
                        {headerData?.urlTemplate ||
                        headerData?.urlTemplate === 0
                          ? headerData.urlTemplate
                          : '-'}
                      </DDSTypography.Title>
                    </DDSTooltip>
                  </div>

                  <div className='flex justify-start gap-[4px] items-baseline override-version'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      className='whitespace-nowrap'
                      color={pallete.colors.textDark6}
                    >
                      {ApiGroupingStringConstants.HTTP_METHOD}:
                    </DDSTypography.Paragraph>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='semiBold'
                      className='whitespace-nowrap'
                      color={pallete.colors.textDark3}
                    >
                      {headerData?.httpMethod || headerData?.httpMethod === 0
                        ? headerData.httpMethod
                        : '-'}
                    </DDSTypography.Paragraph>
                  </div>
                </div>
                <div className='flex items-center justify-end'>
                  <div className='mr-[8px]'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      color={pallete.colors.textDark6}
                    >
                      {ApiGroupingStringConstants.STATUS_CODE_GRAPH}:
                    </DDSTypography.Paragraph>
                  </div>
                  <div className='flex flex-row gap-x-[8px] items-center'>
                    {!loading ? (
                      <StatusCodeGraph
                        tableHeight={''}
                        width='144px'
                        totalCount={totalCount}
                        data={headerData?.statusCodeGraph[headerData?.id]}
                        color={color}
                      />
                    ) : null}
                    <div className='flex justify-end'>
                      <Tooltip
                        id='refresh-tooltip'
                        label='Refresh'
                        position='top'
                      >
                        <Button
                          id='outline-button'
                          type='neutral'
                          onClick={() => store.apiDetailsStore.setRefresh(true)}
                          startIcon={
                            <Renew color={pallete.colors.neuButtonText} />
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-[24px] w-full justify-between relative'>
              {foundOrganization.subscription.trackApiUsage === true ? (
                <div>
                  <Tabs
                    tabOptionsStyle={{
                      minWidth: 'fit-content',
                      width: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '8px 12px',
                      marginRight: '8px'
                    }}
                    tabOptions={tabs}
                    id='tabs-grouped-api'
                    onChange={(index: any) => {
                      setTabIndex(index.toString())
                      setSearchParams({
                        page: '1',
                        tab: index.toString()
                      })
                    }}
                    disabled={loading || store.uiStore.getGlobalLoader()}
                    activeTab={tabIndex}
                  />
                  <div className='flex items-center custom-filter justify-end absolute right-0 top-[32px] space-x-[12px]'>
                    <Filter
                      options={options}
                      isScrollable={true}
                      disabled={loading || store.uiStore.getGlobalLoader()}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div className='flex items-center custom-filter justify-end absolute right-0 top-[0px] space-x-[12px]'>
                    <Filter
                      options={options}
                      isScrollable={true}
                      disabled={loading || store.uiStore.getGlobalLoader()}
                    />
                  </div>
                  <div>
                    <GroupedAPILogTable
                      fetchAPILogFilters={fetchAPILogFilters}
                      viewHeaderGroupedApiData={viewHeaderGroupedApiData}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default observer(ViewGroupedAPIDetails)
