import { ArrowLeft, ChevronLeft } from '@carbon/icons-react'
import { DDSFileUpload, DDSTypography } from 'den-design-system'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  changePassword,
  deleteUserAvatar,
  getUserDetails,
  resendVerification,
  updateUser,
  updateUserPreference,
  uploadUserAvatar
} from '../../../../api/user/User.service'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'

import { Button } from '../../../../components/New/Button/Button'
import DocumentHeader from '../../../../components/Helmet/DocumentHeader'
import { FileUploader } from 'react-drag-drop-files'
import { HeaderConstants } from '../../../../constants/HeaderConstants'
import Input from '../../../../components/New/Input/Input'
import { IoIosArrowForward } from 'react-icons/io'
import Joi from 'joi'
import { OnBoardingType } from '../../../../api/user/User.types'
import { OrganizationUserRoles } from '../../../../enum/OrganizationUserRoles.enum'
import { RefObject } from '../../../../components/common/InputField/Interface/Interface'
import RegexConstants from '../../../../constants/RegexConstants'
import Select from '../../../../components/New/Select/Select'
import SettingConstants from '../../../../constants/SettingConstants'
import Spinner from '../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../constants/StringConstants'
import TimezoneConstants from '../../../../constants/TimeZoneConstantsV2'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../../components/New/Tooltip/Tooltip'
import { UpdatePasswordPopup } from './Components/UpdatePasswordPopup'
import ValidationConstants from '../../../../constants/ValidationConstants'
import { isUndefined } from 'lodash'
import logo from '../../../../assets/images/avatar1.jpg'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import { replaceUrl } from '../../../../utils/UrlUtils'
import routeConstants from '../../../../constants/RouteConstants'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../../store/StoreContext'

const AccountSetting = () => {
  const [profileImage, setProfileImage] = useState<any>(logo)

  // const [deletePopUp, setDeletePopUp] = useState(false)
  const [updatePasswordPopUp, setUpdatePasswordPopUp] = useState(false)
  const [emailInvalid, setEmailInvalid] = React.useState<boolean>(false)
  // const [userName, setUserName] = useState('profileName')
  const [loading, setLoading] = useState(false)
  const fileTypes = ['JPG', 'PNG', 'JPEG']
  const [resendVerificationMailLoader, setResendVerificationMailLoader] =
    useState<any>(false)

  const [getPreferences, setPreferences] = React.useState<any>({})
  const [avatarAlt, setAvatarAlt] = useState('Profile')

  const [avatar, setAvatar] = useState<any>()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const storeContext = useStoreContext()

  const [currentPassword, setCurrentPassword] = React.useState('')
  const currentPasswordRef = useRef<any>()
  const [newPassword, setNewPassword] = React.useState('')
  const newPasswordRef = useRef<any>()
  const [retypeNewPassword, setRetypeNewPassword] = React.useState('')
  const retypeNewPasswordRef = useRef<any>()
  const [helperText, setHelperText] = React.useState('')
  const [error, setError] = React.useState(false)
  const [deleteAvatarLoader, setDeleteAvatarLoader] = useState<boolean>(false)

  const referenceForFirstName = React.useRef<any>(null)
  const referenceForLastName = React.useRef<any>(null)
  const referenceForEmailInput = React.useRef<any>(null)
  const [validationErrorResult, setValidationErrorResult] = useState<any[]>([])
  const [firstNameValidation, setFirstNameValidation] = useState<any[]>([])
  const [lastNameValidation, setLastNameValidation] = useState<any[]>([])
  const [emailFocus, setEmailFocus] = useState<boolean>(false)
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.ACCOUNT_SETTINGS
  )
  const [imageError, setImageError] = useState(false)

  const [imageLoading, setImageLoading] = useState<boolean>(false)
  const referenceForTimezoneDropdown = React.useRef<RefObject>(null)

  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem(StringConstants.ACTIVE_TAB)
  }, [])

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
  })
  const schemaForFirstName = Joi.object({
    firstName: Joi.string()
      .pattern(RegexConstants.NAME_REGEX)
      .required()
      .messages({
        'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
        'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
      })
  })
  const schemaForLastName = Joi.object({
    lastName: Joi.string()
      .pattern(RegexConstants.NAME_REGEX)
      .required()
      .messages({
        'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
        'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
      })
  })

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  // Handler to check if the passwords are valid
  const checkPasswords = (newPass: string, confirmPass: string) => {
    if (
      currentPassword !== '' &&
      newPass === confirmPass && // New and confirm password should match
      newPass !== currentPassword && // New password should not be the same as current password
      RegexConstants.PASSWORD.test(newPass) // New password must pass the regex validation
    ) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }

  const schemaPassword = Joi.object({
    currentPassword: Joi.string().required().messages({
      'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE
    }),
    newPassword: Joi.string()
      .pattern(RegexConstants.PASSWORD)
      .min(8)
      .invalid(Joi.ref('currentPassword'))
      .required()
      .messages({
        'any.invalid': ValidationConstants.PASSWORD.SAME_OLD_PASSWORD,
        'string.pattern.base':
          ValidationConstants.PASSWORD.INVALID_PASSWORD_REGEX,
        'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
        'string.min': ValidationConstants.GENERIC.MIN_LENGTH.replace(
          '{length}',
          '8'
        )
      }),
    retypeNewPassword: Joi.string()
      .required()
      .equal(Joi.ref('newPassword'))
      .messages({
        'string.min': ValidationConstants.GENERIC.MIN_LENGTH.replace(
          '{length}',
          '8'
        ),
        'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
        'any.only': ValidationConstants.PASSWORD.PASSWORDS_MISMATCH
      })
  })

  React.useEffect(() => {
    resetStates()
  }, [])

  const resetStates = () => {
    setCurrentPassword('')
    setNewPassword('')
    setValidationErrorResult([])
    setRetypeNewPassword('')
    setError(false)
    setHelperText('')
    currentPasswordRef.current?.handleTypeChange('password')
    newPasswordRef.current?.handleTypeChange('password')
    retypeNewPasswordRef.current?.handleTypeChange('password')
  }
  const handleUpdatePassword = () => {
    setLoading(true)
    const validationResult = schemaPassword.validate({
      currentPassword,
      newPassword,
      retypeNewPassword
    })

    if (isUndefined(validationResult.error)) {
      setValidationErrorResult([])
      if (!error) {
        changePassword({
          oldPassword: currentPassword,
          newPassword: newPassword
        })
          .then(() => {
            ToastNotification({
              type: 'success',
              message: ToastMessageConstants.AUTH.UPDATE_PASSWORD.SUCCESS
            })

            setUpdatePasswordPopUp(false)
          })
          .catch((err) => {
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.AUTH.UPDATE_PASSWORD.ERROR
            })
          })
          .finally(() => {
            setLoading(false)
            resetStates()
          })
      } else {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AUTH.UPDATE_PASSWORD.VALIDATE
        })
        resetStates()

        setLoading(false)
      }
    } else {
      setLoading(false)
      setValidationErrorResult(validationResult.error.details)
      currentPasswordRef.current?.handleTypeChange('password')
      newPasswordRef.current?.handleTypeChange('password')
      retypeNewPasswordRef.current?.handleTypeChange('password')
    }
  }

  const languageValueToName = (userPreference: any) => {
    for (let i = 0; i < DropdownOptions.length; i++) {
      if (userPreference.language === DropdownOptions[i].value)
        userPreference.language = DropdownOptions[i].label
    }
    return userPreference.language
  }

  const updateEmail = (email: string) => {
    const validationResult = schema.validate({ email })
    if (isUndefined(validationResult.error)) {
      setValidationErrorResult([])
      referenceForEmailInput.current?.startLoading()
      setLoading(true)
      updateUser({
        email: email
      })
        .then((response: any) => {
          storeContext.userStore.setEmail(response.data.email)
          storeContext.userStore.setIsVerified(response.data.isVerified)
          referenceForEmailInput.current?.stopLoading()
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.USER.UPDATED_EMAIL.SUCCESS
          })
          setLoading(false)
          setEmailFocus(false)
        })
        .catch((err: string) => {
          referenceForEmailInput.current?.stopLoading()
          setLoading(false)
          setEmail(storeContext.userStore.getEmail())
          setEmailFocus(false)

          ToastNotification({
            type: 'error',
            message: err
          })
        })
    } else {
      setValidationErrorResult(validationResult.error.details)
      setLoading(false)
    }
  }

  const fileInputRef = useRef<HTMLInputElement>(null)

  const updateFirstName = (firstName: string) => {
    const validationResult = schemaForFirstName.validate({ firstName })
    if (isUndefined(validationResult.error)) {
      setFirstNameValidation([])
      referenceForFirstName.current?.startLoading()
      setLoading(true)
      updateUser({
        firstName: firstName
      })
        .then((response: any) => {
          storeContext.userStore.setFirstName(response.data.firstName)
          setFirstName(response.data.firstName)
          storeContext.userStore.setAvatar(response.data.avatar)
          setAvatar(response.data.avatar)
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.USER.UPDATED_USERNAME.SUCCESS
          })
          referenceForFirstName.current?.stopLoading()
          setLoading(false)
        })
        .catch((err: string) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.USER.UPDATED_USERNAME.ERROR
          })
          setFirstName(storeContext.userStore.getFirstName())
          setLastName(storeContext.userStore.getLastName())
          referenceForFirstName.current?.stopLoading()
          setLoading(false)
        })
    } else {
      setFirstNameValidation(validationResult.error.details)
      setLoading(false)
    }
  }
  const updateLastName = (lastName: string) => {
    const validationResult = schemaForLastName.validate({ lastName })
    if (isUndefined(validationResult.error)) {
      setLastNameValidation([])
      referenceForLastName.current?.startLoading()
      setLoading(true)
      updateUser({
        lastName: lastName
      })
        .then((response: any) => {
          storeContext.userStore.setLastName(response.data.lastName)
          setLastName(response.data.lastName)
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.USER.UPDATED_USERNAME.SUCCESS
          })
          referenceForLastName.current?.stopLoading()
          setLoading(false)
        })
        .catch((err: string) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.USER.UPDATED_USERNAME.ERROR
          })
          setFirstName(storeContext.userStore.getFirstName())
          setLastName(storeContext.userStore.getLastName())
          referenceForLastName.current?.stopLoading()
          setLoading(false)
        })
    } else {
      setLastNameValidation(validationResult.error.details)
      setLoading(false)
    }
  }

  const sendVerificationMail = () => {
    setResendVerificationMailLoader(true)
    setLoading(true)
    resendVerification()
      .then(() => {
        setResendVerificationMailLoader(false)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.USER.RESEND_VERIFICATION.SUCCESS
        })
        setLoading(false)
      })
      .catch((err: string) => {
        setResendVerificationMailLoader(false)
        setLoading(false)
        ToastNotification({
          type: 'error',
          message: err
        })
      })
  }

  React.useEffect(() => {
    setLoading(true)
    setFirstName(storeContext.userStore.getFirstName())
    setLastName(storeContext.userStore.getLastName())
    setAvatar(storeContext.userStore.getAvatar())
    setEmail(storeContext.userStore.getEmail())
    setLoading(false)
    storeContext.uiStore.setGlobalLoader(false)
  }, [storeContext.userStore.getAvatar()])
  React.useEffect(() => {
    storeContext.uiStore.setGlobalLoader(false)
  }, [storeContext.scopeStore.getScope()])

  React.useEffect(() => {
    storeContext.uiStore.setGlobalLoader(false)
  }, [
    storeContext.scopeStore.getEnvironmentId(),
    storeContext.uiStore.getGlobalLoader()
  ])

  useEffect(() => {
    getUserDetails().then((userResponse: any) => {
      const organizationIndex = userResponse.data.organizations.findIndex(
        (org: any) => {
          return org.id === storeContext.userStore.getSelectedOrganization().id
        }
      )

      // if (organizationIndex === -1) {
      //   navigate(routeConstants.UNAUTHORIZED_PAGE)
      // }
    })
  }, [])

  useEffect(() => {
    storeContext.uiStore.setGlobalLoader(false)
  }, [storeContext.uiStore.getGlobalLoader()])

  useEffect(() => {
    setPreferences(storeContext.userStore.getUserPreference())
  }, [storeContext.userStore.getUserPreference()])

  const DropdownOptions = [
    { label: 'English-India', value: 'en_IN' },
    { label: 'English-United States', value: 'en_US' },
    { label: 'English-United Kingdom', value: 'en_GB' },
    { label: 'English-Australia', value: 'en_AU' }
  ]

  const TimezoneDropdownOptions = TimezoneConstants.map((timezoneConstant) => ({
    label:
      timezoneConstant.name.replaceAll('_', ' ') +
      timezoneConstant.label.substring(0, 11),
    value: timezoneConstant.name
  }))

  const updatePasswordButton = () => {
    return (
      <Button
        endIcon={<IoIosArrowForward />}
        id='upload-image'
        type='outline'
        size='small'
        label='Update Password'
        onClick={() => {
          gaEventTracker(gaEventConstants.UPDATE_PASSWORD)
          setUpdatePasswordPopUp(true)
        }}
        disabled={
          loading ||
          storeContext.userStore.getOnBoardedVia() === OnBoardingType.SSO
            ? true
            : false
        }
      />
    )
  }

  const handleSubmit = (file: any) => {
    if (storeContext.uiStore.getGlobalLoader() || loading) return
    setLoading(true)
    storeContext.uiStore.setGlobalLoader(true)
    setImageLoading(true)

    const formData = new FormData()
    formData.append('file', file)

    uploadUserAvatar(formData)
      .then((response: any) => {
        storeContext.userStore.setAvatar(response.data.avatar)
        setAvatar(response.data.avatar)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.SETTINGS.UPLOAD_USER_AVATAR.SUCCESS
        })
      })
      .catch((err: string) => {
        ToastNotification({
          type: 'error',
          message: err
        })
        setAvatar(avatar)
      })
      .finally(() => {
        setImageLoading(false)
        setLoading(false)
        storeContext.uiStore.setGlobalLoader(false)
      })
  }

  const handleDeleteAvatar = () => {
    if (loading || storeContext.uiStore.getGlobalLoader()) return
    setLoading(true)
    storeContext.uiStore.setGlobalLoader(true)
    setDeleteAvatarLoader(true)

    const userId = storeContext.userStore.getId()

    deleteUserAvatar(userId)
      .then((response: any) => {
        console.log(response, 'response')
        storeContext.userStore.setAvatar(response.data.avatar)
        setAvatar(null)
        setAvatarAlt('')
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.SETTINGS.DELETE_AVATAR.SUCCESS
        })
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })
      })
      .finally(() => {
        setDeleteAvatarLoader(false)
        storeContext.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      handleSubmit(file)
      const reader = new FileReader()
      reader.onload = () => {
        setAvatar(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  const filterOption = (input: any, option: any) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const handleChange = (timeZoneSelection: any) => {
    const selectedTimeZone = TimezoneDropdownOptions.find(
      (item) => item.value === timeZoneSelection
    )
    setLoading(true)
    storeContext.uiStore.setGlobalLoader(true)

    if (selectedTimeZone) {
      updateUserPreference({
        timeZone: selectedTimeZone.value
      })
        .then((userPreference: any) => {
          storeContext.userStore.setUserPreference(userPreference.data)
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.PREFERENCE.UPDATE_PREFERENCE.SUCCESS
          })
        })
        .catch((err) => {
          storeContext.userStore.setUserPreference(
            storeContext.userStore.getUserPreference()
          )
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.PREFERENCE.UPDATE_PREFERENCE.ERROR
          })
        })
        .finally(() => {
          setLoading(false)
          storeContext.uiStore.setGlobalLoader(false)
        })
    }
  }

  return (
    <>
      <DocumentHeader header={HeaderConstants.ACCOUNT_SETTINGS} />
      {storeContext.userStore.getOrganizations().length === 0 && (
        <div
          className='flex flex-row space-x-[12px] cursor-pointer pb-[32px] pt-[8px]'
          onClick={() => {
            navigate(routeConstants.NO_ORGANIZATION)
          }}
        >
          <ArrowLeft
            size={20}
            color={pallete.colors.textDark3}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(routeConstants.NO_ORGANIZATION)
            }}
          />
          <DDSTypography.Paragraph
            size='para'
            variant='semiBold'
            color={pallete.colors.textDark3}
          >
            Back
          </DDSTypography.Paragraph>
        </div>
      )}
      {storeContext.uiStore.getGlobalLoader() || loading ? (
        <Spinner />
      ) : (
        <div className='py-[24px]'>
          <div className='w-full h-full'>
            {/* Account Settings Section */}
            <div className='flex flex-col'>
              <DDSTypography.Title
                type='h3'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='mb-[24px]'
              >
                {SettingConstants.ACCOUNT_SETTING}
              </DDSTypography.Title>

              <DDSTypography.Paragraph
                size='para'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='pb-[4px]'
              >
                {SettingConstants.PROFILE_NAME}
              </DDSTypography.Paragraph>

              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark6}
                className='mb-[12px]'
              >
                {SettingConstants.EDIT_YOUR_PROFILE_NAME}
              </DDSTypography.Paragraph>
              <div className='flex items-start gap-[24px] mb-[32px]'>
                <Input
                  onChange={(e: any) => {
                    setFirstName(e.target.value)
                    setFirstNameValidation([])
                  }}
                  onBlur={(e: any) => {
                    if (e.target.value.trim() === '') {
                      setFirstNameValidation([
                        { message: 'Name cannot be empty' }
                      ])
                    } else if (
                      storeContext.userStore.getFirstName() !== e.target.value
                    ) {
                      updateFirstName(e.target.value)
                    }
                  }}
                  maxLength={30}
                  id='first-name-input'
                  name='firstNameInput'
                  value={firstName}
                  required={true}
                  validateInput={true}
                  validate={(value: string) => {
                    if (value.trim() === '') {
                      return {
                        error: true,
                        message: 'First name cannot be empty'
                      }
                    } else if (!RegexConstants.NAME_VALIDATION.test(value)) {
                      return {
                        error: true,
                        message: 'Please enter a valid first name'
                      }
                    }
                    return {
                      error: false,
                      message: ''
                    }
                  }}
                />
                <Input
                  id='last-name-input'
                  name='lastNameInput'
                  value={lastName}
                  onChange={(e: any) => {
                    setLastName(e.target.value)
                    setLastNameValidation([])
                  }}
                  maxLength={30}
                  onBlur={(e: any) => {
                    if (e.target.value.trim() === '') {
                      setLastNameValidation([
                        { message: 'Last name cannot be empty' }
                      ])
                    } else if (
                      storeContext.userStore.getLastName() !== e.target.value
                    ) {
                      updateLastName(e.target.value)
                    }
                  }}
                  required={true}
                  validateInput={true}
                  validate={(value: string) => {
                    if (value.trim() === '') {
                      return {
                        error: true,
                        message: 'Last name cannot be empty'
                      }
                    } else if (!RegexConstants.NAME_VALIDATION.test(value)) {
                      return {
                        error: true,
                        message: 'Please enter a valid last name'
                      }
                    }
                    return {
                      error: false,
                      message: ''
                    }
                  }}
                />
              </div>

              <div className='flex flex-col mb-[12px]'>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='pb-[4px]'
                >
                  {SettingConstants.DISPLAY_PICTURE}
                </DDSTypography.Paragraph>
                <DDSTypography.Paragraph
                  size='para'
                  variant='regular'
                  color={pallete.colors.textDark6}
                >
                  {SettingConstants.ASSIGN_AN_AVATAR}
                </DDSTypography.Paragraph>
              </div>
              <div className='flex items-center gap-[12px]'>
                <div className='relative'>
                  {imageLoading || deleteAvatarLoader ? (
                    <Spinner noMarginTop />
                  ) : (
                    <div className='flex flex-col gap-[8px]'>
                      <div className='relative h-[100px] w-[100px] rounded-full bg-gray-200 flex justify-center items-center overflow-hidden'>
                        <FileUploader
                          handleChange={handleSubmit}
                          name='file'
                          types={fileTypes}
                          maxSize={1}
                          onTypeError={(error: any) =>
                            ToastNotification({
                              type: 'error',
                              message:
                                ToastMessageConstants.SETTINGS
                                  .UPLOAD_USER_AVATAR.ERROR
                            })
                          }
                          disabled={
                            loading ||
                            storeContext.uiStore.getGlobalLoader() ||
                            storeContext.userStore.getNoActiveSubscription()
                          }
                          onSizeError={(error: any) =>
                            ToastNotification({
                              type: 'error',
                              message:
                                ToastMessageConstants.SETTINGS
                                  .UPLOAD_USER_AVATAR.ERROR2
                            })
                          }
                          hoverTitle=' '
                        >
                          <div className='flex w-full h-full cursor-pointer'>
                            <img
                              src={avatar}
                              alt='Avatar'
                              className='rounded-full object-fill h-[100px] w-[100px]'
                              style={{
                                border: '1px solid',
                                borderColor: pallete.colors.stroke2
                              }}
                            />
                          </div>
                        </FileUploader>
                      </div>

                      <div>
                        <DDSTypography.Paragraph
                          size='caption'
                          variant='bold'
                          color={pallete.colors.textDark6}
                        >
                          Note
                        </DDSTypography.Paragraph>
                        <ul
                          className='list-disc pl-[8px]'
                          style={{ color: pallete.colors.textDark6 }}
                        >
                          <li>
                            <DDSTypography.Paragraph
                              size='para'
                              variant='regular'
                              color={pallete.colors.textDark6}
                            >
                              JPG, JPEG, and PNG are allowed
                            </DDSTypography.Paragraph>
                          </li>
                          <li>
                            <DDSTypography.Paragraph
                              size='para'
                              variant='regular'
                              color={pallete.colors.textDark6}
                            >
                              Maximum upload file size - 1MB
                            </DDSTypography.Paragraph>
                          </li>
                        </ul>
                      </div>

                      <div className='pt-[8px] flex flex-row gap-[12px]'>
                        <Button
                          id='upload-image'
                          type='outline'
                          size='small'
                          label='Upload Image'
                          disabled={
                            loading ||
                            storeContext.uiStore.getGlobalLoader() ||
                            storeContext.userStore.getNoActiveSubscription()
                          }
                          onClick={() => fileInputRef.current?.click()}
                        />
                        <Button
                          id='remove-image'
                          type='danger'
                          size='small'
                          disabled={
                            loading ||
                            storeContext.uiStore.getGlobalLoader() ||
                            storeContext.userStore.getNoActiveSubscription() ||
                            storeContext.userStore
                              .getAvatar()
                              .includes('/common')
                          }
                          label={
                            <DDSTypography.Paragraph
                              size='para'
                              variant='semiBold'
                              color={pallete.colors.warningRed1}
                            >
                              {SettingConstants.REMOVE}
                            </DDSTypography.Paragraph>
                          }
                          onClick={handleDeleteAvatar}
                        />
                      </div>
                    </div>
                  )}
                  {/* Hidden input to trigger file upload */}
                  <input
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept='image/jpeg, image/png, image/jpg'
                  />
                </div>
              </div>
            </div>

            <div className='py-[32px]'>
              <hr style={{ color: pallete.colors.stroke2 }} />
            </div>

            {/* Security Settings Section */}
            <div className='flex flex-col w-[100%]'>
              <DDSTypography.Title
                type='h3'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='mb-[24px]'
              >
                {SettingConstants.SECURITY_SETTING}
              </DDSTypography.Title>

              <div className='flex flex-col w-[100%]'>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='pb-[4px]'
                >
                  {SettingConstants.UPDATE_EMAIL}
                </DDSTypography.Paragraph>
                <DDSTypography.Paragraph
                  size='para'
                  variant='regular'
                  color={pallete.colors.textDark6}
                >
                  {SettingConstants.UPDATE_VERIFY}
                </DDSTypography.Paragraph>
                <div className='flex gap-[12px] mt-[12px] w-[100%]'>
                  <Input
                    type='email'
                    width='40vh'
                    value={email}
                    onChange={(e: any) => {
                      setEmail(e.target.value)
                    }}
                    onBlur={(e: any) => {
                      const email = e.target.value
                      if (
                        email !== storeContext.userStore.getEmail() &&
                        RegexConstants.EMAIL_REGEX.test(email)
                      ) {
                        updateEmail(email)
                      } else {
                        setEmailFocus(false)
                        setValidationErrorResult([])
                      }
                    }}
                    onFocus={() => {
                      setEmailFocus(true)
                    }}
                    validateInput={true}
                    validate={(value: string) => {
                      if (!RegexConstants.EMAIL_REGEX.test(value)) {
                        return {
                          error: true,
                          message: 'Please enter valid email address'
                        }
                      } else if (value === '') {
                        return {
                          error: false,
                          message: 'Email cannot be empty'
                        }
                      }
                      return {
                        error: false,
                        message: ''
                      }
                    }}
                    onError={(error) => {
                      setEmailInvalid(error)
                    }}
                    id='email-input'
                    name='emailInput'
                  />

                  <div className='flex justify-center items-center'>
                    <Button
                      id='resend-verification'
                      type='filled'
                      size='small'
                      label='Resend Verification'
                      disabled={
                        storeContext.userStore.getIsVerified() === true ||
                        loading
                      }
                      loading={resendVerificationMailLoader}
                      onClick={() => {
                        gaEventTracker(gaEventConstants.RESEND_VERIFICATION)
                        if (!storeContext.userStore.getIsVerified()) {
                          sendVerificationMail()
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`flex flex-col gap-[4px] ${
                  emailInvalid ? 'mt-[18px]' : ''
                }`}
              >
                <DDSTypography.Paragraph
                  size='caption'
                  variant='regular'
                  color={pallete.colors.warningRed2}
                >
                  {storeContext.userStore.getIsVerified() === false &&
                    'Your account is not verified'}
                </DDSTypography.Paragraph>
              </div>

              <div className='flex flex-col mt-[32px]'>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='pb-[4px]'
                >
                  {SettingConstants.UPDATE_PASSWORD}
                </DDSTypography.Paragraph>
                <div>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='regular'
                    color={pallete.colors.textDark6}
                  >
                    {SettingConstants.ENTER_CONFIRM}
                  </DDSTypography.Paragraph>
                </div>
                <div className='mt-[12px] flex'>
                  {storeContext.userStore.getOnBoardedVia() ===
                  OnBoardingType.SSO ? (
                    <div>
                      <Tooltip
                        id='update-button-settings'
                        label={
                          'Please update password on the salesforce platform'
                        }
                        position='top'
                        className='inline-flex '
                      >
                        {updatePasswordButton()}
                      </Tooltip>
                    </div>
                  ) : (
                    updatePasswordButton()
                  )}
                </div>
              </div>
            </div>

            <div className='py-[32px]'>
              <hr style={{ color: pallete.colors.stroke2 }} />
            </div>

            {/* Language and Time Section */}
            <div className='flex flex-col'>
              <DDSTypography.Title
                type='h3'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='mb-[24px]'
              >
                {SettingConstants.LANGUAGE_TIME}
              </DDSTypography.Title>

              <DDSTypography.Paragraph
                size='para'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='pb-[4px]'
              >
                {SettingConstants.LANGUAGE}
              </DDSTypography.Paragraph>

              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark6}
                className='mb-[12px]'
              >
                {SettingConstants.SELECT_PREFERRED_LANGUAGE}
              </DDSTypography.Paragraph>
              <div className='flex items-start gap-[32px] mb-[24px]'>
                <Select
                  id='language'
                  variant='top'
                  size='extraLarge'
                  name='language'
                  value={languageValueToName(
                    storeContext.userStore.getUserPreference()
                  )}
                  options={DropdownOptions}
                  searchable={false}
                  placeholder='Select Role'
                  onChange={(languageSelection: any) => {
                    languageSelection = DropdownOptions.find(
                      (item) => item === languageSelection
                    )
                    updateUserPreference({
                      language: languageSelection.value
                    }).then((userPreference: any) => {
                      storeContext.userStore.setUserPreference(
                        userPreference.data
                      )
                    })
                  }}
                  disabled={true}
                />
              </div>

              <DDSTypography.Paragraph
                size='para'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='pb-[4px]'
              >
                {SettingConstants.TIME_ZONE}
              </DDSTypography.Paragraph>

              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark6}
                className='mb-[12px]'
              >
                {SettingConstants.SELECT_PREFERRED_TIME_ZONE}
              </DDSTypography.Paragraph>
              <div className='flex items-start override-padding-timezone gap-[32px] override-filter-option'>
                <Select
                  filterOption={filterOption}
                  id='member-role'
                  variant='top'
                  size='extraLarge'
                  name='Member Role'
                  value={storeContext.userStore.getUserPreference().timeZone}
                  options={TimezoneDropdownOptions}
                  searchable={true}
                  placeholder='Select Role'
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <UpdatePasswordPopup
            showHeader
            title='Update Password'
            modalOpen={updatePasswordPopUp}
            setModalOpen={setUpdatePasswordPopUp}
            primaryButtonText='Update Password'
            primaryButtonClick={handleUpdatePassword}
            secondaryButtonText='Cancel'
            secondaryButtonClick={() => {
              setUpdatePasswordPopUp(false)
              setCurrentPassword('')
              setNewPassword('')
              setRetypeNewPassword('')
            }}
            loading={loading}
            primaryButtonDisabled={isButtonDisabled}
            showFooter={true}
            content={
              <div className='flex flex-col p-[24px] gap-[24px]'>
                <Input
                  width='100%'
                  type='password'
                  value={currentPassword}
                  onChange={(e: any) => {
                    const value = e.target.value
                    setCurrentPassword(value)
                    checkPasswords(newPassword, retypeNewPassword)
                  }}
                  validateInput={true}
                  onBlur={(e: any) => {
                    setCurrentPassword(e.target.value)
                  }}
                  labelPosition='top'
                  label='Current Password'
                  id='current-password-input'
                  name='currentPassword'
                  required={true}
                  validationRegex={new RegExp(RegexConstants.PASSWORD)}
                  validate={(value: string) => {
                    if (value === '') {
                      return {
                        error: true,
                        message: 'Password cannot be empty'
                      }
                    }
                    return {
                      error: false,
                      message: ''
                    }
                  }}
                  placeholder='Enter current password'
                />

                <Input
                  width='100%'
                  type='password'
                  validateInput={true}
                  value={newPassword}
                  onChange={(e: any) => {
                    const value = e.target.value
                    setNewPassword(value)
                    checkPasswords(e.target.value, retypeNewPassword)
                  }}
                  onBlur={(e: any) => {
                    setNewPassword(e.target.value)
                  }}
                  labelPosition='top'
                  label='New Password'
                  id='new-password-input'
                  name='newPassword'
                  required={true}
                  validationRegex={new RegExp(RegexConstants.PASSWORD)}
                  validate={(value: string) => {
                    if (value === '') {
                      return {
                        error: true,
                        message: 'Password cannot be empty'
                      }
                    } else if (!RegexConstants.PASSWORD.test(value)) {
                      return {
                        error: true,
                        message:
                          'Use minimum 8 characters, upper & lower case, 1 number, 1 special character'
                      }
                    } else if (value === currentPassword) {
                      return {
                        error: true,
                        message:
                          'New password should not be the same as the Current password'
                      }
                    }
                    return {
                      error: false,
                      message: ''
                    }
                  }}
                  placeholder='Enter new password'
                />

                <Input
                  width='100%'
                  value={retypeNewPassword}
                  validateInput={true}
                  type='password'
                  required={true}
                  validationRegex={new RegExp(RegexConstants.PASSWORD)}
                  validate={(value: string) => {
                    if (value === '') {
                      return {
                        error: true,
                        message: 'Password cannot be empty'
                      }
                    } else if (value !== newPassword) {
                      return {
                        error: true,
                        message:
                          'New password and Confirm password do not match'
                      }
                    }
                    return {
                      error: false,
                      message: ''
                    }
                  }}
                  onChange={(e: any) => {
                    const value = e.target.value
                    setRetypeNewPassword(value)
                    checkPasswords(newPassword, e.target.value)
                  }}
                  labelPosition='top'
                  label='Confirm New Password'
                  id='confirm-password-input'
                  name='confirmPassword'
                  placeholder='Enter confirm new password'
                />
              </div>
            }
          />
        </div>
      )}
    </>
  )
}

export default observer(AccountSetting)
