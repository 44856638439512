import React, { useEffect } from 'react'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'

import APIUsage from '../../../components/APIMonitoring/APIUsage'
import AllAPITab from '../../../components/APIMonitoring/AllAPITab/AllAPITab'
import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { Button } from '../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import GroupedAPITab from '../../../components/APIMonitoring/GroupedAPITab/GroupedAPITab'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import { Renew } from '@carbon/icons-react'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const APIGroupedAll: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const store = useStoreContext()
  const [tabIndex, setTabIndex] = React.useState<string>(
    searchParams.get('tab') ?? '1'
  )
  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  const tabs = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Grouped APIs
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Grouped APIs
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <GroupedAPITab />
        </div>
      )
    },
    {
      label:
        tabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            All APIs
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            All APIs
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <AllAPITab />
        </div>
      )
    }
  ]

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
    const tabFromParams = searchParams.get('tab')
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (tabFromParams) {
      setTabIndex(tabFromParams.toString())
    } else {
      setSearchParams({ ...allQueryParams, tab: '1' })
    }
    if (tabFromParams === '1') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: ApiGroupingStringConstants.GROUPED_API,
        link:
          replaceUrl(
            routeConstants.API_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?tab=1'
      })
    } else if (tabFromParams === '2') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: ApiGroupingStringConstants.ALL_API,
        link:
          replaceUrl(
            routeConstants.API_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?tab=2'
      })
    }
  }, [store.breadcrumbStore.getBreadcrumbsOptions(), tabIndex])

  return (
    <>
      <DocumentHeader header={HeaderConstants.API_MONITORING} />
      <div className='flex flex-col w-full'>
        <div className='relative w-full flex justify-between'>
          <div
            style={{
              width: '100%',
              display: 'flex'
            }}
            className='tab-child'
          >
            <Tabs
              tabOptionsStyle={{
                minWidth: 'fit-content',
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 12px',
                marginRight: '8px'
              }}
              tabOptions={tabs}
              id='tabs-grouped-api'
              onChange={(index: any) => {
                if (tabIndex != index) {
                  setTabIndex(index.toString())
                  setSearchParams({
                    ...searchParams,
                    tab: index.toString(),
                    contextSwitcherTab:
                      searchParams.get('contextSwitcherTab') ?? '1'
                  })
                }
              }}
              activeTab={tabIndex}
              disabled={store.uiStore.getGlobalLoader()}
            />
          </div>
          <div className='absolute right-[0px]'>
            <Tooltip id='refresh-tooltip' label='Refresh' position='top'>
              <Button
                id='outline-button'
                type='neutral'
                disabled={
                  store.apiDetailsStore.getRefresh() ||
                  store.uiStore.getGlobalLoader()
                }
                onClick={() => {
                  store.apiDetailsStore.setRefresh(true)
                }}
                startIcon={<Renew color={pallete.colors.neuButtonText} />}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(APIGroupedAll)
