export const settingData = [
  {
    key: '1',
    projectName: 'Dev-Test 1',
    createdBy: 'tharun',
    createdOn: 'Jun 15, 2023'
  },
  {
    key: '2',
    projectName: 'Dev-Test 2',
    createdBy: 'tharun',
    createdOn: 'Jun 15, 2023'
  },
  {
    key: '3',
    projectName: 'Dev-Test 3',
    createdBy: 'tharun',
    createdOn: 'Jun 15, 2023'
  }
]

export const memberData = [
  {
    key: '1',
    user: 'Kopika ',
    count: '1',
    status: 'Failure'
  },
  {
    key: '2',
    user: 'Kopika ',
    count: '6',
    status: 'Failure'
  },
  {
    key: '3',
    user: 'Kopika ',
    count: '7',
    status: 'Success'
  }
]

export const groupedUserDetails = [
  {
    key: '1',
    endpoint: '/app/respond/503/ ',
    httpMethod: 'POST',
    apiCount: '10',
    lastCall: 'March 23, 2024',
    art: '10 sec'
  },
  {
    key: '2',
    endpoint: '/app/respond/503/ ',
    httpMethod: 'POST',
    apiCount: '10',
    lastCall: 'March 23, 2024',
    art: '10 sec'
  },
  {
    key: '3',
    endpoint: '/app/respond/503/ ',
    httpMethod: 'POST',
    apiCount: '10',
    lastCall: 'March 23, 2024',
    art: '10 sec'
  },
  {
    key: '4',
    endpoint: '/app/respond/503/ ',
    httpMethod: 'POST',
    apiCount: '10',
    lastCall: 'March 23, 2024',
    art: '10 sec'
  },
  {
    key: '5',
    endpoint: '/app/respond/503/ ',
    httpMethod: 'POST',
    apiCount: '10',
    lastCall: 'March 23, 2024',
    art: '10 sec'
  },
  {
    key: '6',
    endpoint: '/app/respond/503/ ',
    httpMethod: 'POST',
    apiCount: '10',
    lastCall: 'March 23, 2024',
    art: '10 sec'
  }
]

export const groupedUserDetailsLogs = [
  {
    key: '1',
    endpoint: '1.0',
    httpMethod: '200',
    apiCount: '10',
    lastCall: 'Mar 03, 2024 6:24:41 PM',
    art: '10 sec'
  },
  {
    key: '2',
    endpoint: '1.0',
    httpMethod: '200',
    apiCount: '10',
    lastCall: 'Mar 03, 2024 6:24:41 PM',
    art: '10 sec'
  },
  {
    key: '3',
    endpoint: '1.0',
    httpMethod: '200',
    apiCount: '10',
    lastCall: 'Mar 03, 2024 6:24:41 PM',
    art: '10 sec'
  },
  {
    key: '4',
    endpoint: '1.0',
    httpMethod: '200',
    apiCount: '10',
    lastCall: 'Mar 03, 2024 6:24:41 PM',
    art: '10 sec'
  },
  {
    key: '5',
    endpoint: '1.0',
    httpMethod: '200',
    apiCount: '10',
    lastCall: 'Mar 03, 2024 6:24:41 PM',
    art: '10 sec'
  },
  {
    key: '6',
    endpoint: '1.0',
    httpMethod: '200',
    apiCount: '10',
    lastCall: 'Mar 03, 2024 6:24:41 PM',
    art: '10 sec'
  }
]

export const inviterData = [
  {
    key: '1',
    inviter: 'Kopika Updated Dev Testing',
    inviterEmail: 'kopika+hybrid@foxsense.io',
    inviteeEmail: 'kopika+hybrid@foxsense.io',
    role: 'Member',
    invitedOn: 'Apr 05, 2024 12:53 PM'
  },
  {
    key: '2',
    inviter: 'Kopika Updated Dev Testing',
    inviterEmail: 'kopika+hybrid@foxsense.io',
    inviteeEmail: 'kopika+hybrid@foxsense.io',
    role: 'Member',
    invitedOn: 'Apr 05, 2024 12:53 PM'
  },
  {
    key: '3',
    inviter: 'Kopika Updated Dev Testing',
    inviterEmail: 'kopika+hybrid@foxsense.io',
    inviteeEmail: 'kopika+hybrid@foxsense.io',
    role: 'Member',
    invitedOn: 'Apr 05, 2024 12:53 PM'
  },
  {
    key: '4',
    inviter: 'Kopika Updated Dev Testing',
    inviterEmail: 'kopika+hybrid@foxsense.io',
    inviteeEmail: 'kopika+hybrid@foxsense.io',
    role: 'Member',
    invitedOn: 'Apr 05, 2024 12:53 PM'
  }
]

export const environmentData = [
  {
    key: '1',
    name: 'Test',
    apiKey: 'cf83e7ee-fcee-4903-8926-a1a33f8c09d9',
    createdOn: 'June 12, 2024'
  },
  {
    key: '2',
    name: 'Test',
    apiKey: 'cf83e7ee-fcee-4903-8926-a1a33f8c09d9',
    createdOn: 'June 12, 2024'
  },
  {
    key: '3',
    name: 'Test',
    apiKey: 'cf83e7ee-fcee-4903-8926-a1a33f8c09d9',
    createdOn: 'June 12, 2024'
  }
]

export const hostData = [
  {
    key: '1',
    allowedHost: '*'
  }
]

export const billingData = [
  {
    key: '1',
    features: 'Uptime Checks',
    totalUtilised: '4 / month',
    maximumLimit: '45000 / month'
  },
  {
    key: '2',
    features: 'Uptime Checks',
    totalUtilised: '4 / month',
    maximumLimit: '45000 / month'
  },
  {
    key: '3',
    features: 'Uptime Checks',
    totalUtilised: '4 / month',
    maximumLimit: '45000 / month'
  },
  {
    key: '4',
    features: 'Uptime Checks',
    totalUtilised: '4 / month',
    maximumLimit: '45000 / month'
  }
]

export const billingInvoice = [
  {
    key: '1',
    invoice: 'Basic Plan - July 2024',
    amount: 'USD $20',
    status: 'Paid',
    date: 'Jul 5 , 2024'
  },
  {
    key: '2',
    invoice: 'Basic Plan - July 2024',
    amount: 'USD $20',
    status: 'Paid',
    date: 'Jul 5 , 2024'
  },
  {
    key: '3',
    invoice: 'Basic Plan - July 2024',
    amount: 'USD $20',
    status: 'Paid',
    date: 'Jul 5 , 2024'
  },
  {
    key: '4',
    invoice: 'Basic Plan - July 2024',
    amount: 'USD $20',
    status: 'Paid',
    date: 'Jul 5 , 2024'
  }
]

export const plans = [
  {
    title: 'Basic',
    description: 'Suitable for individuals/smaller teams',
    price: '$199/mo'
  },
  {
    title: 'Standard',
    description: 'Great for growing teams',
    price: '$399/mo',
    currentPlan: true
  },
  {
    title: 'Premium',
    description: 'Perfect for larger organizations',
    price: '$499/mo'
  },
  {
    title: 'Enterprise',
    description: 'Custom solutions for enterprises',
    price: 'Lets talk'
  }
]
export const yearPlans = [
  {
    title: 'Basic',
    description: 'Suitable for individuals/smaller teams',
    price: '$169/mo',
    discount: true,
    discount_percent: 'Save $30/mon'
  },
  {
    title: 'Standard',
    description: 'Great for growing teams',
    price: '$339/mo',
    discount: true,
    currentPlan: true,
    discount_percent: 'Save $60/mon'
  },
  {
    title: 'Premium',
    description: 'Perfect for larger organizations',
    price: '$424/mo',
    discount: true,
    discount_percent: 'Save $75/mon'
  },
  {
    title: 'Enterprise',
    description: 'Custom solutions for enterprises',
    price: 'Lets talk'
  }
]

export const features = [
  {
    id: '1',
    leftText: 'Basic',
    rightText: 'Suitable for individuals/smaller teams',
    price: '$199/mo'
  },
  {
    id: '2',
    leftText: 'Standard',
    rightText: 'Great for growing teams',
    price: '$299/mo'
  },
  {
    id: '3',
    leftText: 'Premium',
    rightText: 'Perfect for larger organizations',
    price: '$399/mo'
  },
  {
    id: '4',
    leftText: 'Enterprise',
    rightText: 'Custom solutions for enterprises',
    price: '$499/mo'
  },
  {
    id: '5',
    leftText: 'Premium',
    rightText: 'Perfect for larger organizations',
    price: '$399/mo'
  },
  {
    id: '6',
    leftText: 'Enterprise',
    rightText: 'Custom solutions for enterprises',
    price: '$499/mo'
  },
  {
    id: '7',
    leftText: 'Standard',
    rightText: 'Great for growing teams',
    price: '$299/mo'
  },
  {
    id: '8',
    leftText: 'Premium',
    rightText: 'Perfect for larger organizations',
    price: '$399/mo'
  },
  {
    id: '9',
    leftText: 'Enterprise',
    rightText: 'Custom solutions for enterprises',
    price: '$499/mo'
  },
  {
    id: '10',
    leftText: 'Premium',
    rightText: 'Perfect for larger organizations',
    price: '$399/mo'
  },
  {
    id: '11',
    leftText: 'Enterprise',
    rightText: 'Custom solutions for enterprises',
    price: '$499/mo'
  },
  {
    id: '12',
    leftText: 'Premium',
    rightText: 'Perfect for larger organizations',
    price: '$399/mo'
  },
  {
    id: '13',
    leftText: 'Enterprise',
    rightText: 'Custom solutions for enterprises',
    price: '$499/mo'
  },
  {
    id: '14',
    leftText: 'Enterprise',
    rightText: 'Custom solutions for enterprises',
    price: '$499/mo'
  },
  {
    id: '15',
    leftText: 'Premium',
    rightText: 'Perfect for larger organizations',
    price: '$399/mo'
  },
  {
    id: '16',
    leftText: 'Enterprise',
    rightText: 'Custom solutions for enterprises',
    price: '$499/mo'
  },
  {
    id: '17',
    leftText: 'Enterprise',
    rightText: 'Custom solutions for enterprises',
    price: '$499/mo'
  },
  {
    id: '18',
    leftText: 'Premium',
    rightText: 'Perfect for larger organizations',
    price: '$399/mo'
  },
  {
    id: '19',
    leftText: 'Enterprise',
    rightText: 'Custom solutions for enterprises',
    price: '$499/mo'
  },
  {
    id: '20',
    leftText: 'Enterprise',
    rightText: 'Custom solutions for enterprises',
    price: '$499/mo'
  }
]

export default {
  //ACCOUNT SETTING
  ACCOUNT_SETTING: 'Account Settings',
  PROFILE_NAME: 'Profile Name',
  EDIT_YOUR_PROFILE_NAME: 'Edit your profile name',
  DISPLAY_PICTURE: 'Display Picture',
  ASSIGN_AN_AVATAR: 'Assign an avatar for your profile name',
  REMOVE: 'Remove',
  SECURITY_SETTING: 'Security Settings',
  UPDATE_EMAIL: 'Update Email Address',
  UPDATE_VERIFY: 'Update and verify your email',
  UPDATE_PASSWORD: 'Update Password',
  ENTER_CONFIRM: 'Enter and confirm your new password',
  LANGUAGE_TIME: 'Language and Time',
  LANGUAGE: 'Language',
  SELECT_PREFERRED_LANGUAGE: 'Select preferred language',
  TIME_ZONE: 'Timezone',
  SELECT_PREFERRED_TIME_ZONE: 'Select preferred Timezone',

  //BILLING
  INVOICE: 'Invoice',
  AMOUNT: 'Amount',
  STATUS: 'Status',
  DATE: 'Date',
  FEATURES: 'Features you get',
  TOTAL_UTILIZED: 'Total Utilised',
  MAXIMUM_LIMIT: 'Maximum Limit',
  CHOOSE_PLAN: 'Current Plan',
  CURRENT_PLAN: 'Cancel Plan',
  CHANGE_BILLING_CYCLE:
    '(Changes will take effect from the next billing cycle)',
  CANCEL_PLAN: 'Cancel Plan',
  UPGRADE_PLAN: 'Are you sure you want to upgrade now?',
  AUTO_RENEW: 'Your plan is going to auto-renew on 15 Aug, 2024',
  CANCEL_BILLING: ' Are your sure you want to cancel?',
  CANCEL_SUBSCRIPTION:
    ' Your subscription will be canceled from next billing cycle',

  //General organization Setting

  GENERAL: 'General',
  ORGANIZATION_NAME: 'Organization Name',
  CUSTOMIZE_EACH_PROJECT_WITH_A_UNIQUE_NAME:
    'Customize each organization with a unique name',
  ORGANIZATION_TYPE: 'Organization Type',
  SALESFORCE_FULLSTACK: 'Salesforce / Full-stack / Mulesoft',
  OWNERSHIP: 'Ownership',
  ROLES_ACTION: 'Roles & Actions',
  NOTE: 'Note: The person should be a member of this organization',

  // manage organization settings

  INVITE_MEMBER_TO_PROJECT: 'Invite members to your Project',
  LETS_COLLABORATE: 'Lets Collaborate!!',
  MEMBER_EMAIL: 'Member Email',
  DELETE_ORGANIZATION:
    'Are you sure you want to delete tharun kishore from the organization?',

  //PROJECT SETTING

  PROJECT_NAME: 'Project Name',
  PROJECT_TYPE: 'Project Type',
  ENVIRONMENT: 'Environment',
  CREATED_BY: ' Created By',
  CREATED_ON: ' Created On',
  MANAGE_APPLICATION:
    'Manage applications and modules individually as projects!',

  MEMBER: 'Member',
  DELETE_PROJECT: 'Deleting this project is permanent. Are you sure you want',
  TO_PROCEED: 'to proceed?',
  TEXT_CONFIRMATION: 'Text Confirmation',
  TYPE: 'Type',
  TO_PROCEED_TYPE: 'to proceed',

  ALLOWED_HOST: 'Allowed Host',
  NAME: 'Name',
  API_KEY: 'Api Key',
  ENVIRONMENTS: 'Environments',
  HOSTS: 'Hosts',
  EXAMPLE_LINK: 'Examples: https://example.com, *, 184.169.250.146.',
  TOGGLE:
    'Disabling the toggle prevents notification messages from being sent from the',
  LIVE_ENVIRONMENT: 'Live environment.',
  DELETE_HOSTS: 'Are you sure you want to delete',
  FOXSENSE_LINK: 'https://www.foxsense.io/?',

  UNIQUE_NAME: 'Customize each project with a unique name',
  NOTIFICATIONS: 'Notifications',
  SLACK_NOTIFICATIONS: 'Slack Notification',
  SLACK_NOTIFIED: 'Get notified instantly on Slack',
  TEAM: 'Teams Webhook URL',
  MICROSOFT_NOTIFIED: 'Get notified instantly on Microsoft Teams',
  ADDITIONAL_EMAIL: 'Additional Emails',
  EMAIL_FROM_VIGIL: 'Get notified on emails which are not part of Vigil',
  ASSIGN_MEMBER_FROM_ORGANIZATION:
    'Assign Members from your organization to be part of your project',
  DEV_TEST: 'Assign Member to Dev-test',

  USER: 'User',
  ROLE: 'Role',
  EMAIL: 'Email',
  MEMBERS: 'Members',
  ADD_MEMBER: 'Add Member',
  MANAGE_USERS: 'Manage Users'
}
