export const HeaderConstants = {
  VIGIL: 'Vigil',
  ERROR_PAGE: 'Not Found | Vigil',
  NO_RECORD: 'No Record | Vigil',
  UNAUTHORIZED_PAGE: 'Unauthorized | Vigil',
  UNDERMAINTENANCE_PAGE: 'Under Maintenance | Vigil',
  ALL_PAGES: 'All Pages | Vigil',
  RESET_PASSWORD: 'Reset Password | Vigil',
  FORGOT_PASSWORD: 'Forgot Password | Vigil',
  INVITE_MEMBERS: 'Invite Member | Vigil',
  INVITES: 'Invites | Vigil',
  GET_STARTED: 'Get Started | Vigil',
  ACCOUNT_SETTINGS: 'Account Settings | Vigil',
  ORGANIZATION_SETTINGS: 'General Settings | Vigil',
  ORGANIZATION_SETTING: 'Organization Settings | Vigil',
  MEMBER_SETTINGS: 'Member Settings | Vigil',
  PROJECT_SETTINGS: 'Project Settings | Vigil',
  SUBSCRIPTION_SETTINGS: 'Subscription Settings | Vigil',
  BILLING_SETTINGS: 'Billing Settings | Vigil',
  PREFERENCE_SETTINGS: 'Preferences | Vigil',
  PLANS: 'Plans | Vigil',
  AHC: 'Health Checks | Vigil',
  UPTIME_CHECKS: 'Uptime Checks | Vigil',
  SCHEDULE_JOBS: 'Scheduled Jobs | Vigil',
  INCIDENT: 'Incidents | Vigil',
  SANDBOX: 'Sandbox | Vigil',
  ERROR: 'Errors | Vigil',
  EXCEPTIONS: 'Exceptions | Vigil',
  APEX_JOBS: 'APEX Jobs | Vigil',
  ESCALATIONS: 'Escalations | Vigil',
  GROUPED_ERRORS: 'Grouped Errors | Vigil',
  JOB_MONITORING: 'Job Monitoring | Vigil',
  API_MONITORING: 'API Monitoring | Vigil',
  ARCHIVED_API_RECORDS: 'API Monitoring | Vigil',
  ERROR_MONITORING: 'Exceptions | Vigil',
  ERROR_DETAILS: 'Exceptions | Vigil',
  OVERVIEW: 'Home | Vigil',
  HEALTH_STATUS: 'Health Check | Vigil',
  ISSUES: 'Vigil',
  CREATE_ORGANIZATION: 'Organization | Vigil',
  SIGN_UP: 'Sign Up | Vigil',
  SIGN_IN: 'Sign In | Vigil',
  COMING_SOON: 'Coming Soon | Vigil',
  REDIRECT: 'Vigil',
  NOTIFICATIONS: 'Notifications | Vigil',
  INVITATIONS: 'Invitations | Vigil',
  SETTINGS: 'Settings | Vigil',
  NO_ORGANIZATION: 'Organization | Vigil',
  NO_PROJECT: 'Project | Vigil',
  INVITE_REDIRECT: 'Invites | Vigil',
  EXCEPTION_INCIDENTS: 'Incidents | Vigil',
  ESCALATION_POLICIES: 'Escalations | Vigil',
  SALESFORCE_AUDIT: 'Salesforce Audit | Vigil'
}
