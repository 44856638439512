import { ChevronDown, Close } from '@carbon/icons-react'
import { DDSSelectV2, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import {
  addHealthCheck,
  getApiHealth,
  getHealthReportDetails,
  updateHealthCheck
} from '../../api/appHealthCheck/AHC.service'
import { useNavigate, useParams } from 'react-router-dom'

import AssignedToMenuList from '../common/AssignedToMenuList/AssignedToMenuList'
import BottomBar from '../New/BottomBar/BottomBar'
import { Button } from '../New/Button/Button'
import { CheckData } from '../../types/store.types'
import Input from '../New/Input/Input'
import RegexConstants from '../../constants/RegexConstants'
import Select from '../New/Select/Select'
import Spinner from '../common/Spinner/Spinner'
import Switch from '../New/Switch/Switch'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import UptimeStringConstants from '../../constants/UptimeStringConstants'
import UrlConstants from '../../constants/UrlConstants'
import ValidationConstants from '../../constants/ValidationConstants'
import { checkNameAvailability } from '../../api/common/Common.service'
import { getAllProjectMembers } from '../../api/project/ProjectMember.service'
import pallete from '../../global/newPallete'
import { replaceUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'
import { useStoreContext } from '../../store/StoreContext'

interface AddUpdateCheckProps {
  isAdd: boolean
}

const UCAddUpdateCheckForm: React.FC<AddUpdateCheckProps> = ({ isAdd }) => {
  const store = useStoreContext()
  const { id } = useParams()
  const [headersList, setHeadersList] = useState<any>([])
  const [userOptions, setUserOptions] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [contentDisplayMonitor, setContentDisplayMonitor] =
    useState<boolean>(false)
  const [healthData, setHealthData] = useState<any>()
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [createDisabled, setCreateDisabled] = useState(true)
  const [checkNameNotAvailable, setCheckNameNotAvailable] =
    useState<boolean>(false)
  const [checkNameErrorText, setCheckNameErrorText] = useState<string>('')
  const navigate = useNavigate()
  const defaultForm = {
    checkName: '',
    url: '',
    type: 'https',
    checkContent: false,
    checkInterval: '15',
    checkIntervalUnit: 'mins',
    alertCandidates: null,
    authUsername: '',
    authPassword: '',
    elementId: '',
    customHeaders: null
  }
  const [fetchedData, setFetchedData] = useState<any>(defaultForm)
  const [formData, setFormData] = useState(defaultForm)
  const [headers, setHeaders] = useState({
    headerData: '',
    valueData: ''
  })

  const fetchHealthReportDetails = () => {
    setLoading(true)
    getApiHealth({ $ahcId: id as string })
      .then((response: any) => {
        const {
          checkName,
          url,
          type,
          checkContent,
          checkInterval,
          checkIntervalUnit,
          alertCandidates,
          authUsername,
          authPassword,
          elementId,
          customHeaders
        } = response.data
        setFormData((prevFormData) => ({
          ...prevFormData,
          checkName: checkName,
          url: url,
          type: type,
          checkInterval: checkInterval.toString(),
          checkIntervalUnit: checkIntervalUnit,
          authUsername: authUsername,
          authPassword: authPassword,
          elementId: elementId,
          checkContent: checkContent
        }))

        const alertedUsers =
          alertCandidates?.map((candidate: any) => ({
            id: candidate.projectMemberId,
            name: `${candidate.user.firstName} ${candidate.user.lastName}`, // Concatenate first and last names
            imgUrl: candidate.user.avatar
          })) || []
        setSelectedUsers(alertedUsers)

        const headersArray = customHeaders
          ? Object.entries(customHeaders).map(([headerKey, headerValue]) => ({
              headerData: headerKey,
              valueData: headerValue
            }))
          : []
        setHeadersList(headersArray.reverse())
        setFetchedData((prevData: any) => ({
          ...prevData,
          checkName: checkName,
          url: url,
          type: type,
          checkInterval: checkInterval.toString(),
          checkIntervalUnit: checkIntervalUnit,
          authUsername: authUsername,
          authPassword: authPassword,
          elementId: elementId,
          checkContent: checkContent,
          alertCandidates: alertedUsers,
          customHeaders: headersArray
        }))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getProjectMembers()
  }, [])

  useEffect(() => {
    if (id) {
      fetchHealthReportDetails()
    }
  }, [id])

  useEffect(() => {
    const requiredFields = [
      formData.checkName,
      formData.url,
      formData.checkInterval
    ]
    if (formData.checkContent) {
      requiredFields.push(formData.elementId)
    }

    const validUrl = RegexConstants.URL_REGEX.test(formData.url)

    const areRequiredFieldsFilled = requiredFields.every(
      (field) => field !== ''
    )

    setCreateDisabled(
      (!isAdd && !checkIfChanged()) ||
        !areRequiredFieldsFilled ||
        !validUrl ||
        selectedUsers.length === 0
    )
  }, [formData, selectedUsers, headersList])

  const handleInputChange = (name: string, value: string) => {
    if (name === 'checkName') {
      setCheckNameNotAvailable(false)
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value
    }))
  }
  const handleCloseHeader = (id: number) => {
    const newHeadersList = headersList.filter(
      (_: any, index: number) => index !== id
    )
    setHeadersList(newHeadersList)
  }
  const checkIntervalOptions = [
    {
      value: '1',
      label: '1 minute'
    },
    {
      value: '5',
      label: '5 minutes'
    },
    {
      value: '15',
      label: '15 minutes'
    }
  ]

  const callAddCheckFunction = (payload: any) => {
    addHealthCheck(payload)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.AHC.CREATE_AHC.SUCCESS
        })
        navigate(
          replaceUrl(
            routeConstants.AHC_PROJECTS_DASHBOARD,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?page=1'
        )
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })
      })
      .finally(() => setLoading(false))
  }

  const callUpdateCheckFunction = (payload: any) => {
    updateHealthCheck(payload)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.AHC.UPDATE_CHECKS.SUCCESS
        })
        navigate(
          replaceUrl(
            routeConstants.AHC_PROJECTS_DASHBOARD,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?page=1'
        )
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AHC.UPDATE_CHECKS.ERROR
        })
      })
      .finally(() => setLoading(false))
  }
  const handleAddCheck = () => {
    setLoading(true)
    const customHeaders = headersList.reduce(
      (acc: Record<string, string>, header: any) => {
        if (header.headerData && header.valueData) {
          acc[header.headerData] = header.valueData
        }
        return acc
      },
      {}
    )
    const alertCandidates = selectedUsers.map((user: any) => user.id)
    const payload: CheckData = {
      checkName: formData.checkName,
      url: formData.url,
      checkInterval: parseInt(formData.checkInterval, 10),
      authUsername: formData.authUsername,
      authPassword: formData.authPassword,
      customHeaders,
      alertCandidates,
      type: formData.type,
      checkIntervalUnit: formData.checkIntervalUnit,
      elementId: formData.elementId,
      checkContent: formData.checkContent
    }
    checkNameAvailability(
      UrlConstants.CHECK_HEALTH_CHECK_NAME_AVAILABILITY.USECASE,
      formData.checkName.trim()
    )
      .then(() => {
        setCheckNameNotAvailable(false)
        callAddCheckFunction(payload)
      })
      .catch(() => {
        setCheckNameNotAvailable(true)
        setCheckNameErrorText(
          ValidationConstants.APP_HEALTH_CHECK
            .APP_HEALTH_CHECK_NAME_NOT_AVAILABLE
        )
        setLoading(false)
      })
  }

  const handleUpdateCheck = () => {
    setLoading(true)
    const customHeaders = headersList.reduce(
      (acc: Record<string, string>, header: any) => {
        if (header.headerData && header.valueData) {
          acc[header.headerData] = header.valueData
        }
        return acc
      },
      {}
    )
    const alertCandidates = selectedUsers.map((user: any) => user.id)
    const payload = {
      $ahcId: id as string,
      checkName: formData.checkName,
      url: formData.url,
      checkInterval: parseInt(formData.checkInterval, 10),
      authUsername: formData.authUsername,
      authPassword: formData.authPassword,
      customHeaders,
      alertCandidates,
      type: formData.type,
      checkIntervalUnit: formData.checkIntervalUnit,
      elementId: formData.elementId,
      checkContent: formData.checkContent
    }
    if (formData.checkName !== fetchedData.checkName) {
      checkNameAvailability(
        UrlConstants.CHECK_HEALTH_CHECK_NAME_AVAILABILITY.USECASE,
        formData.checkName.trim()
      )
        .then(() => {
          setCheckNameNotAvailable(false)
          callUpdateCheckFunction(payload)
        })
        .catch(() => {
          setCheckNameNotAvailable(true)
          setCheckNameErrorText(
            ValidationConstants.APP_HEALTH_CHECK
              .APP_HEALTH_CHECK_NAME_NOT_AVAILABLE
          )
          setLoading(false)
        })
    } else {
      callUpdateCheckFunction(payload)
    }
  }

  const checkIfChanged = () => {
    const compareById = (arr1: any[], arr2: any[]) => {
      const ids1 = arr1?.map((item) => item.id).sort()
      const ids2 = arr2?.map((item) => item.id).sort()
      return JSON.stringify(ids1) === JSON.stringify(ids2)
    }
    const compareHeaders = (arr1: any[], arr2: any[]) => {
      const sortedArr1 = arr1
        ?.map((item) => ({
          headerData: item.headerData,
          valueData: item.valueData
        }))
        .sort(
          (a, b) =>
            a.headerData.localeCompare(b.headerData) ||
            a.valueData.localeCompare(b.valueData)
        )

      const sortedArr2 = arr2
        ?.map((item) => ({
          headerData: item.headerData,
          valueData: item.valueData
        }))
        .sort(
          (a, b) =>
            a.headerData.localeCompare(b.headerData) ||
            a.valueData.localeCompare(b.valueData)
        )

      return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2)
    }

    const isChanged =
      formData.checkName !== fetchedData.checkName ||
      formData.url !== fetchedData.url ||
      formData.type !== fetchedData.type ||
      formData.checkInterval !== fetchedData.checkInterval ||
      formData.checkIntervalUnit !== fetchedData.checkIntervalUnit ||
      formData.authUsername !== fetchedData.authUsername ||
      formData.authPassword !== fetchedData.authPassword ||
      formData.elementId !== fetchedData.elementId ||
      formData.checkContent !== fetchedData.checkContent ||
      !compareById(selectedUsers, fetchedData.alertCandidates) ||
      !compareHeaders(headersList, fetchedData.customHeaders)

    return isChanged
  }

  const getProjectMembers = () => {
    const projectId = store.scopeStore.getSelectedProject().id
    getAllProjectMembers({ projectId: projectId as string })
      .then((response: any) => {
        const allMembers = response.data.map((member: any) => {
          return {
            id: member.id,
            name: member.user.firstName + ' ' + member.user.lastName,
            imgUrl: member.user.avatar,
            role: member.role
          }
        })
        setUserOptions(allMembers)
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.PROJECT_MEMBER.FIND_ALL_PROJECT_MEMBERS.ERROR
        })
      })
      .finally(() => store.uiStore.setGlobalLoader(false))
  }
  return loading ? (
    <div className='flex flex-col h-[70vh] gap-[24px] px-[32px] py-[24px] self-stretch justify-center'>
      <Spinner />
    </div>
  ) : (
    <>
      <div className='flex flex-col h-full gap-[24px] px-[32px] py-[24px] self-stretch'>
        <DDSTypography.Title
          variant='semiBold'
          type='h3'
          color={pallete.colors.textDark3}
        >
          {isAdd
            ? UptimeStringConstants.ADD_CHECK_TITLE
            : UptimeStringConstants.UPDATE_CHECK_TITLE}
        </DDSTypography.Title>
        <div className='flex flex-col self-stretch items-start gap-[16px]'>
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.textDark4}
          >
            Primary Info
          </DDSTypography.Title>
          <div className='flex items-start self-stretch gap-[24px] pb-[8px]'>
            <div className='flex flex-col relative'>
              <Input
                onChange={(e) => handleInputChange('checkName', e.target.value)}
                value={formData.checkName}
                labelPosition='top'
                validateInput
                maxLength={30}
                label='Application Name'
                required={true}
                size='large'
                id='app-name-input'
                name={'Application name'}
              />
              {checkNameNotAvailable && (
                <div>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='regular'
                    className='absolute -bottom-5'
                    color={pallete.colors.warningRed2}
                  >
                    {checkNameErrorText}
                  </DDSTypography.Paragraph>
                </div>
              )}
            </div>

            <Input
              onChange={(e) => {
                handleInputChange('url', e.target.value)
              }}
              value={formData.url}
              labelPosition='top'
              label='URL'
              required={true}
              validateInput
              validationRegex={RegexConstants.URL_REGEX}
              size='large'
              id='url-input'
              name='URL'
            />
            <Select
              size='large'
              label='Check Interval'
              id='check-interval-select'
              value={formData.checkInterval}
              name='Check Interval'
              required={true}
              searchable={false}
              options={checkIntervalOptions}
              onSelect={(value) => handleInputChange('checkInterval', value)}
            />
          </div>
          <div className='flex gap-[8px] items-center'>
            <DDSTypography.Title
              type='h5'
              className='whitespace-nowrap'
              variant='semiBold'
              color={pallete.colors.textDark3}
            >
              Content Display Monitoring
            </DDSTypography.Title>
            <Switch
              id='content-switch'
              value={formData?.checkContent}
              onChange={(value) => {
                setFormData({
                  ...formData,
                  ['elementId']: '',
                  ['checkContent']: value
                })
              }}
            />
          </div>
          {formData.checkContent && (
            <div className='flex flex-col items-start gap-[8px]'>
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                color={pallete.colors.textDark3}
              >
                Enter the ID value of a HTML tag that is associated to an active
                element of your website.
              </DDSTypography.Paragraph>
              <Input
                onChange={(e) => handleInputChange('elementId', e.target.value)}
                value={formData.elementId}
                placeholder='Enter ID'
                required={true}
                validateInput
                errorMessage='Element ID cannot be empty'
                size='large'
                id='enter-id-input'
                name='Element ID'
              />
            </div>
          )}
        </div>
        <div
          className={`h-[1px] w-full `}
          style={{ backgroundColor: pallete.colors.stroke2 }}
        />
        <div className='flex flex-col gap-[12px] w-fit'>
          <DDSTypography.Title
            type='h5'
            className='whitespace-nowrap'
            variant='semiBold'
            color={pallete.colors.textDark4}
          >
            Alert Settings
            <span style={{ color: pallete.colors.warningRed2 }}>*</span>
          </DDSTypography.Title>
          <AssignedToMenuList
            label='assign'
            arrowPosition='left'
            menuPosition='right'
            maxHeight='128px'
            userOptions={userOptions}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        </div>
        <div
          className={`h-[1px] w-full `}
          style={{ backgroundColor: pallete.colors.stroke2 }}
        />
        <div className='flex flex-col gap-[12px]'>
          <DDSTypography.Title
            type='h5'
            className='whitespace-nowrap'
            variant='semiBold'
            color={pallete.colors.textDark4}
          >
            Authentication
          </DDSTypography.Title>
          <div className='flex items-start self-stretch gap-[24px]'>
            <Input
              onChange={(e) =>
                handleInputChange('authUsername', e.target.value)
              }
              labelPosition='top'
              value={formData.authUsername}
              label='Username'
              size='large'
              id='username-input'
              name='username'
            />
            <Input
              onChange={(e) =>
                handleInputChange('authPassword', e.target.value)
              }
              value={formData.authPassword}
              labelPosition='top'
              label='Password'
              type='password'
              size='large'
              id='pw-input'
              name='password'
            />
          </div>
        </div>
        <div
          className={`h-[1px] w-full `}
          style={{ backgroundColor: pallete.colors.stroke2 }}
        />
        <div className='flex flex-col items-start self-stretch gap-[12px]'>
          <DDSTypography.Title
            type='h5'
            className='whitespace-nowrap'
            variant='semiBold'
            color={pallete.colors.textDark4}
          >
            Http Header
          </DDSTypography.Title>
          <div className='flex gap-[12px]'>
            <div className='flex gap-[24px]'>
              <Input
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { name, value } = event.target
                  setHeaders({
                    ...headers,
                    [name]: value
                  })
                }}
                placeholder='Enter Header'
                value={headers.headerData}
                labelPosition='top'
                label='Header'
                size='large'
                id='header-input'
                name='headerData'
              />
              <Input
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { name, value } = event.target
                  setHeaders({
                    ...headers,
                    [name]: value
                  })
                }}
                placeholder='Enter Value'
                value={headers.valueData}
                labelPosition='top'
                label='Value'
                size='large'
                id='value-input'
                name='valueData'
              />
            </div>
            <div className='flex items-end'>
              <Button
                label='Add Header'
                disabled={headers.headerData === '' || headers.valueData === ''}
                onClick={() => {
                  if (headers.headerData !== '' && headers.valueData !== '') {
                    setHeadersList([headers, ...headersList])
                    setHeaders({ headerData: '', valueData: '' })
                  }
                }}
                id='button'
              />
            </div>
          </div>

          {headersList.length > 0 && (
            <div className='flex flex-col gap-[12px]'>
              {headersList.map((item: any, index: number) => (
                <div key={index} className='flex gap-[4px]'>
                  <div className='flex gap-[24px]'>
                    <div
                      id='header-chip'
                      className={`flex items-center px-[16px] py-[8px] border rounded-md h-[36px] w-[290px] self-stretch`}
                      style={{ borderColor: pallete.colors.stroke2 }}
                    >
                      <DDSTypography.Paragraph variant='medium' size='para'>
                        {item.headerData}
                      </DDSTypography.Paragraph>
                    </div>
                    <div
                      id='value-chip'
                      style={{ borderColor: pallete.colors.stroke2 }}
                      className={`flex items-center px-[16px] py-[8px] border rounded-md h-[36px] w-[290px] self-stretch`}
                    >
                      <DDSTypography.Paragraph variant='medium' size='para'>
                        {item.valueData}
                      </DDSTypography.Paragraph>
                    </div>
                  </div>
                  <div className='flex'>
                    <div className='flex items-center justify-center px-[12px]'>
                      <Close
                        width='16px'
                        height='16px'
                        className='cursor-pointer'
                        onClick={() => handleCloseHeader(index)}
                        color={pallete.colors.textButtonText}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <BottomBar>
        <Button
          id='cancel-btn'
          disabled={loading}
          onClick={() => {
            navigate(
              replaceUrl(
                routeConstants.AHC_PROJECTS_DASHBOARD,
                store.scopeStore.getScope()['organization-id'] as string,
                store.scopeStore.getScope()['project-id'] as string,
                store.scopeStore.getScope()['environment-id'] as string
              ) + '?page=1'
            )
          }}
          type='neutral'
          label='Cancel'
        />
        {isAdd ? (
          <Button
            id='add-check-btn'
            label='Create Check'
            disabled={createDisabled || loading}
            loading={loading}
            onClick={handleAddCheck}
          />
        ) : (
          <Button
            id='add-check-btn'
            label='Update Check'
            disabled={createDisabled || loading}
            loading={loading}
            onClick={handleUpdateCheck}
          />
        )}
      </BottomBar>
    </>
  )
}

export default UCAddUpdateCheckForm
