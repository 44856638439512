import React, { useEffect, useState } from 'react'
import {
  downloadAPIGroupedURLView,
  downloadAPIGroupedUserView,
  getAPIGroupFilters
} from '../../../api/apiMonitoring/ApiMonitoring.service'

import { Button } from '../../New/Button/Button'
import ContextSwitcherTabs from '../../common/DDS/ContextSwitcherTab/ContextSwitcherTab'
import { Download } from '@carbon/icons-react'
import Filter from '../../Filter/Filter'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import URLView from './Screen/URLView'
import UserView from './Screen/UserView'
import { convertJsonToCsvDownload } from '../../../utils/FileUtils'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const GroupedAPITab = () => {
  const store = useStoreContext()

  const [searchParams, setSearchParams] = useSearchParams()
  const [tabIndex, setTabIndex] = useState<string>(
    searchParams.get('contextSwitcherTab') ?? '1'
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<any>([])

  const downloadRecords = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParamsForDownload = {
      ...allQueryParams,
      'time-zone': store.userStore.getUserPreference().timeZone
    }
    downloadAPIGroupedURLView(queryParamsForDownload)
      .then((response: any) => {
        const date = new Date()
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`
        convertJsonToCsvDownload(
          response.data,
          `Grouped_API_${formattedDate}.csv`
        )
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: 'Error downloading log in API Grouped URL view'
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const downloadUserRecords = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParamsForDownload = {
      ...allQueryParams,
      'time-zone': store.userStore.getUserPreference().timeZone
    }
    downloadAPIGroupedUserView(queryParamsForDownload)
      .then((response: any) => {
        const date = new Date()
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`
        convertJsonToCsvDownload(
          response.data,
          `Grouped_API_${formattedDate}.csv`
        )
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: 'Error downloading log in API Grouped User view'
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const downloadRecordsNoUserName = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParamsForDownload = {
      ...allQueryParams,
      'time-zone': store.userStore.getUserPreference().timeZone
    }

    downloadAPIGroupedURLView(queryParamsForDownload)
      .then((response: any) => {
        // Filter out the apiUserName property
        const filteredData = response.data.map(
          ({
            userName,
            userCount,
            userId,
            ...rest
          }: {
            userName: string
            userCount: string
            userId: string
            [key: string]: any
          }) => rest
        )

        const date = new Date()
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`
        convertJsonToCsvDownload(
          filteredData,
          `Grouped_API_${formattedDate}.csv`
        )
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: 'Error downloading log in all API URL view'
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
  }, [])

  const getGroupedUrlFilter = () => {
    getAPIGroupFilters()
      .then((response: any) => {
        const filteredOptions = response.data.filter(
          (item: any) => item.heading !== 'Users'
        )
        setOptions(filteredOptions)
      })
      .catch((err) => {
        return ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
  }

  const getGroupedUserFilter = () => {
    getAPIGroupFilters()
      .then((response: any) => {
        const filteredOptions = response.data.filter(
          (item: any) =>
            item.heading !== 'URL Template' && item.heading !== 'HTTP Method'
        )
        setOptions(filteredOptions)
      })
      .catch((err) => {
        return ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
  }

  useEffect(() => {
    if (tabIndex === '1') {
      getGroupedUrlFilter()
    } else {
      getGroupedUserFilter()
    }
  }, [tabIndex, store.scopeStore.getScope()])

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  const ContextSwitcherTab = [
    {
      id: '1',
      label: 'URL View',
      children: (
        <div>
          <URLView
            loading={loading}
            setLoading={setLoading}
            getGroupedFilter={getGroupedUrlFilter}
          />
        </div>
      )
    },
    {
      id: '2',
      label: 'User View',
      children: (
        <div className='relative'>
          <UserView
            loading={loading}
            setLoading={setLoading}
            getGroupedUserFilter={getGroupedUserFilter}
          />
        </div>
      )
    }
  ]

  return (
    <>
      {foundOrganization.subscription.trackApiUsage === true ? (
        <div className='pt-[24px]'>
          <div className='flex items-center justify-between w-full'>
            <div className='flex-grow override-view-more-tabs'>
              <ContextSwitcherTabs
                tabOptions={ContextSwitcherTab}
                shape='roundedRectangle'
                tabStyle={{
                  border: '1px solid',
                  borderColor: pallete.colors.stroke2,
                  userSelect: 'none',
                  width: '204px'
                }}
                tabTitleClassName='text-center select-none'
                activeTab={tabIndex}
                onChange={(index: any) => {
                  setTabIndex(index)
                  setSearchParams({
                    page: '1',
                    tab: searchParams.get('tab') ?? '1',
                    contextSwitcherTab: index
                  })
                }}
                disabled={loading || store.uiStore.getGlobalLoader()}
                className='override-height override-view-more-tabs'
              />

              <div className='flex items-center custom-filter justify-end absolute right-0 top-[72px] space-x-[12px]'>
                <Filter
                  options={options}
                  isScrollable={true}
                  disabled={loading || store.uiStore.getGlobalLoader()}
                />

                <Button
                  id='outline-button'
                  label='Export'
                  type='neutral'
                  size='small'
                  disabled={loading || store.uiStore.getGlobalLoader()}
                  onClick={() => {
                    if (tabIndex === '1') downloadRecords()
                    else {
                      downloadUserRecords()
                    }
                  }}
                  startIcon={<Download color={pallete.colors.neuButtonText} />}
                />

                {/* <Search
              placeholder='Search by URL, HTTP Method...'
              id='log-search'
              name='logSearch'
              width='35vh'
              onChange={() => {
                // Implement search functionality
              }}
            /> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className='pt-[24px]'>
            <div className='flex items-center justify-between w-full'>
              <div className='flex-grow override-view-more-tabs'></div>
            </div>

            <div
              className={`flex items-center custom-filter justify-end  space-x-[12px]`}
            >
              <Filter
                options={options}
                isScrollable={true}
                disabled={loading || store.uiStore.getGlobalLoader()}
              />

              <Button
                id='outline-button'
                label='Export'
                type='neutral'
                size='small'
                disabled={loading || store.uiStore.getGlobalLoader()}
                onClick={() => downloadRecordsNoUserName()}
                startIcon={<Download color={pallete.colors.neuButtonText} />}
              />
            </div>

            <div>
              <URLView
                loading={loading}
                setLoading={setLoading}
                getGroupedFilter={getGroupedUrlFilter}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default observer(GroupedAPITab)
