import { ChevronRight, Renew } from '@carbon/icons-react'
import { DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import {
  UrlWithOrgProjEnvIds,
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import {
  getAPIGroupUserFilters,
  getApiUserById
} from '../../../api/apiMonitoring/ApiMonitoring.service'
import { isNull, isUndefined } from 'lodash'
import { msToTimeUnits, timeZone } from '../../../utils/DateTimeUtils'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { Button } from '../../New/Button/Button'
import Filter from '../../Filter/Filter'
import NoFilterImage from '../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../common/NoContent/NoRecord'
import Spinner from '../../common/Spinner/Spinner'
import Status from '../Components/Status'
import StringConstants from '../../../constants/StringConstants'
import Table from '../../common/DDS/Table/Table'
import Tabs from '../../common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import Tooltip from '../../New/Tooltip/Tooltip'
import { getGroupsByApiUserId } from '../../../api/apiMonitoring/ApiMonitoring.service'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { useStoreContext } from '../../../store/StoreContext'

const GroupedUserViewDetails = () => {
  const store = useStoreContext()
  const { id } = useParams()

  const [tableData, setTableData] = useState<any>()
  const [userData, setUserData] = useState<any>()

  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const [options, setOptions] = useState<any>([])
  const [userLoading, setUserLoading] = useState<boolean>(true)
  const initialPage = searchParams.get('page') ?? '1'
  const [currentPage, setCurrentPage] = useState<number>(parseInt(initialPage))

  const navigate = useNavigate()
  const { id: group_id } = useParams()
  const [tabIndex, setTabIndex] = React.useState<string>('1')

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
  }, [])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  const allAPIUserFilter = () => {
    getAPIGroupUserFilters({ $id: id as string })
      .then((response: any) => {
        store.apiDetailsStore.setRefresh(false)
        const filteredOptions = response.data.filter(
          (item: any) =>
            item.heading !== 'Response Status' &&
            item.heading !== 'Users' &&
            item.heading !== 'Client Version'
        )
        setOptions(filteredOptions)
      })
      .catch((err) => {
        return ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
  }

  const viewGroupedApiDataById = async () => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    getGroupsByApiUserId({
      id: id as string,
      ...allQueryParams,
      size: StringConstants.DEFAULT_TABLE_SIZE
    })
      .then((response: any) => {
        setTableData(response.data)
        setTotalCount(response.totalCount)
        setCurrentPage(response.page)
        store.apiDetailsStore.setRefresh(false)
        store.filterStore.setFilters(response.filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const viewApiUserById = async () => {
    setUserLoading(true)
    store.uiStore.setGlobalLoader(true)
    getApiUserById({ $id: id as string })
      .then((response: any) => {
        setUserData(response)
        store.apiDetailsStore.setRefresh(false)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setUserLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
      viewGroupedApiDataById()
      viewApiUserById()
    } else {
      setSearchParams({
        ...allQueryParams,
        page: '1'
      })
      setCurrentPage(1)
    }
  }, [searchParams, store.scopeStore.getScope()])

  useEffect(() => {
    allAPIUserFilter()
  }, [])

  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      viewGroupedApiDataById()
      viewApiUserById()
      allAPIUserFilter()
    }
  }, [store.apiDetailsStore.getRefresh()])

  useEffect(() => {
    if (!isUndefined(userData?.userName)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: ApiGroupingStringConstants.GROUPED_API,
          link:
            replaceUrl(
              routeConstants.API_MONITORING,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ) + '?page=1&contextSwitcherTab=2'
        },
        {
          label: userData?.userName,
          link:
            replaceUrl(
              routeConstants.GROUPED_USER_DETAILS,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ).replace(':id', id as string) + '?tab=1'
        },
        {
          label: 'API Logs',
          link: `${routeConstants.GROUPED_USER_DETAILS.replace(':id', id as string)}?tab=1`
        }
      ])
    }
  }, [store.breadcrumbStore.getBreadcrumbsOptions(), userData])

  const columns = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.ENDPOINT}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'urlTemplate',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start max-w-[200px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              className={`override-tooltip-arrow ${
                typeof text === 'string' && text.length > 15 ? text : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[200px]'
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row items-center justify-center text-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.HTTP_METHOD}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'httpMethod',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.API_COUNT}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'apiCount',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Last Call Date
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'lastCallDate',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              text,
              timeFormats.fullMonthDateYearTimeWithSeconds
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Status
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'successApiCount',
      render: (text: any, fullItem: any) => {
        return (
          <div className='flex justify-center items-center'>
            <Status
              count={fullItem?.apiCount - fullItem?.successApiCount}
              label='Failure'
              textColor={pallete.colors.warningRed1}
              bgColor={pallete.colors.warningRed5}
              borderTopLeftRadius='4px'
              borderBottomLeftRadius='4px'
            />
            <Status
              count={fullItem?.successApiCount}
              label='Success'
              textColor={pallete.colors.warningGreen}
              bgColor={pallete.colors.warningGreenBg}
              borderTopRightRadius='4px'
              borderBottomRightRadius='4px'
            />
          </div>
        )
      }
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Average Response Time
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'averageResponseTimeInMilliSeconds',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text ? msToTimeUnits(text) : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              navigate(
                UrlWithOrgProjEnvIds(
                  routeConstants.GROUPED_USER_API_LOGS_DETAILS.replace(
                    ':apiGroupId',
                    fullItem.id as string
                  ).replace(':apiUserId', id as string),
                  store
                )
              )
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]

  const tabs = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            API Logs
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            API Logs
          </DDSTypography.Title>
        ),
      children: (
        <>
          {loading ||
          userLoading ||
          store.uiStore.getGlobalLoader() ||
          !tableData ? (
            <Spinner />
          ) : totalCount > 0 ? (
            <div className='mt-[24px] flex w-full '>
              <Table
                tableStyle={{
                  borderColor: pallete.colors.stroke2
                }}
                data={tableData}
                columns={columns}
                totalCount={totalCount}
                rowsPerPage={10}
                currentPage={currentPage}
                onPageChange={(page: number) => {
                  const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  setCurrentPage(page)
                  setSearchParams({ ...allQueryParams, page: page.toString() })
                }}
                loading={loading}
              />
            </div>
          ) : (
            <>
              <NoRecord
                imageSrc={NoFilterImage}
                style={{ height: '74vh' }}
                text={
                  totalCount === 0 && store.filterStore.getFiltersApplied() ? (
                    <>No results found for applied filter.</>
                  ) : (
                    'No Record Found'
                  )
                }
              />
            </>
          )}
        </>
      )
    }
    // {
    //   label:
    //     tabIndex === '2' ? (
    //       <DDSTypography.Title
    //         type='h5'
    //         variant='semiBold'
    //         color={pallete.colors.primary2}
    //       >
    //         API Overview
    //       </DDSTypography.Title>
    //     ) : (
    //       <DDSTypography.Title
    //         type='h5'
    //         variant='medium'
    //         color={pallete.colors.textDark5}
    //       >
    //         API Overview
    //       </DDSTypography.Title>
    //     ),
    //   children: (
    //     <div>
    //       <APIUsage />
    //     </div>
    //   )
    // }
  ]

  return (
    <div className='flex flex-col w-full'>
      <div className='flex justify-between items-center pt-[24px]'>
        <div>
          <DDSTooltip
            id='overview-tooltip'
            position='top'
            className={`override-tooltip-arrow ${
              typeof userData?.userName === 'string' &&
              userData?.userName.length > 30
                ? userData?.userName
                : '!hidden'
            }`}
            shape='roundedRectangle'
            label={
              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark4}
              >
                {userData?.userName}
              </DDSTypography.Paragraph>
            }
            style={{
              backgroundColor: pallete.colors.surface1,
              zIndex: 999999
            }}
          >
            <DDSTypography.Title
              type='h3'
              variant='semiBold'
              color={pallete.colors.textDark3}
              className='truncate max-w-[600px] override-tooltip-arrow'
            >
              {userData?.userName}
            </DDSTypography.Title>
          </DDSTooltip>
        </div>
        <div className='flex justify-end items-center'>
          <Tooltip id='refresh-tooltip' label='Refresh' position='top'>
            <Button
              id='outline-button'
              type='neutral'
              onClick={() => store.apiDetailsStore.setRefresh(true)}
              startIcon={<Renew color={pallete.colors.neuButtonText} />}
            />
          </Tooltip>
        </div>
      </div>
      <div className='pt-[24px] w-full justify-between relative'>
        <div>
          <Tabs
            tabOptionsStyle={{
              minWidth: 'fit-content',
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px 12px',
              marginRight: '8px'
            }}
            tabOptions={tabs}
            id='tabs-grouped-api'
            onChange={(index: any) => {
              if (tabIndex != index) {
                setTabIndex(index.toString())
                setSearchParams({
                  ...searchParams,
                  tab: index.toString()
                })
              }
            }}
            activeTab={tabIndex}
          />
          <div className='flex custom-filter flex-row gap-x-[8px] absolute top-[24px] right-0'>
            <Filter
              options={options}
              isScrollable={true}
              disabled={loading || store.uiStore.getGlobalLoader()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(GroupedUserViewDetails)
