import { ChevronDown, ChevronUp, Settings } from '@carbon/icons-react'
import { DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef } from 'react'

import StringConstants from '../../../constants/StringConstants'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

interface DropdownItemProps {
  title?: string
  logo?: string
  organizationClick?: (data: any) => void
  settingClick?: () => void
  handleSelectButton?: () => void
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  title,
  logo,
  organizationClick,
  settingClick,
  handleSelectButton
}) => {
  const store = useStoreContext()
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    const currentExpandedState = store.uiStore.getMenuExpanded()
    store.uiStore.setMenuExpanded(!currentExpandedState)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        store.uiStore.setMenuExpanded(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    store.uiStore.setMenuExpanded(false)
  }, [])

  return (
    <div
      ref={dropdownRef}
      className={`relative px-[20px] py-[8px] rounded ${
        store.uiStore.getGlobalLoader() ||
        store.uiStore.getprojectEnvironmentLoader() ||
        !store.scopeStore.getProjectId()?.length
          ? 'cursor-not-allowed pointer-events-none'
          : ''
      }`}
      style={{ backgroundColor: pallete.colors.darkBlue }}
      onClick={handleToggle}
    >
      <div
        className='flex items-center justify-between'
        style={
          store.uiStore.getGlobalLoader() ||
          store.uiStore.getprojectEnvironmentLoader() ||
          !store.scopeStore.getProjectId()?.length
            ? { cursor: 'not-allowed', pointerEvents: 'none' }
            : { cursor: 'pointer' }
        }
      >
        <div className='flex justify-center items-center rounded-full  '>
          <div className='w-[28px] h-[28px] relative object-cover'>
            <div className='w-[28px] h-[28px]  border border-white rounded-full object-cover overflow-hidden  '>
              <img
                src={logo}
                alt={title}
                className='w-full h-full rounded-full object-cover'
              />
            </div>
          </div>
          <DDSTooltip
            id={`${title}`}
            label={
              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark4}
              >
                {title}
              </DDSTypography.Paragraph>
            }
            shape='roundedRectangle'
            position='top'
            className={`override-tooltip-arrow ${
              typeof title === 'string' && title.length > 15 ? title : '!hidden'
            }`}
            style={{
              backgroundColor: pallete.colors.surface1,
              zIndex: 99999999999
            }}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='semiBold'
              color={pallete.colors.white}
              className='ml-[8px] truncate max-w-[160px]'
            >
              {title}
            </DDSTypography.Paragraph>
          </DDSTooltip>
        </div>
        <div className='flex items-center gap-[24px'>
          {!store.uiStore.getMenuExpanded() ? (
            <ChevronDown
              className='w-[16px] h-[16px]'
              style={{ pointerEvents: 'none' }}
            />
          ) : (
            <ChevronUp
              className='w-[16px] h-[16px]'
              style={{ pointerEvents: 'none' }}
            />
          )}
        </div>
      </div>

      {store.uiStore.getMenuExpanded() && (
        <div
          className={`z-[99999999] relative gap-[20px] pt-[20px]
           flex flex-col max-h-[100vh] h-[18vh] 2xl:h-[15vh] overflow-y-auto
           ${
             store.uiStore.getSidebarExpanded()
               ? ''
               : 'flex absolute left-[100%] ml-[16px] top-[0px] !w-[300px] max-w-[300px]'
           }
           custom-scrollbar ${
             store.uiStore.getGlobalLoader() ||
             store.uiStore.getprojectEnvironmentLoader() ||
             (!store.scopeStore.getProjectId()?.length &&
               'cursor-not-allowed pointer-events-none')
           }`}
        >
          {store.userStore.getOrganizations().map((item) => (
            <>
              <div
                key={item.id}
                onClick={() => {
                  organizationClick && organizationClick(item)
                  store.uiStore.setMenuExpanded(false)
                }}
                className={`relative flex items-center w-full  ${
                  store.uiStore.getGlobalLoader() ||
                  store.uiStore.getprojectEnvironmentLoader() ||
                  (!store.scopeStore.getProjectId()?.length &&
                    'cursor-not-allowed pointer-events-none')
                } justify-between gap-[20px] px-[8px] rounded-md cursor-pointer ${
                  store.scopeStore.getOrganizationId() === item.id
                    ? 'bg-[#066CFF]'
                    : 'hover:text-[#066CFF]'
                }`}
              >
                <div className='flex items-center text-left justify-start gap-x-[8px] w-full'>
                  <div className='w-[28px] h-[28px] relative object-cover'>
                    <div className='w-[28px] h-[28px]  border border-white rounded-full object-cover overflow-hidden  '>
                      <img
                        src={item.avatar}
                        alt={item.name}
                        className='w-full h-full rounded-full object-cover'
                      />
                    </div>
                  </div>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='semiBold'
                    color={pallete.colors.white}
                    className='max-w-[120px] truncate hover:text-[#066CFF]'
                  >
                    {item.name}
                  </DDSTypography.Paragraph>
                </div>
                <div>
                  {store.scopeStore.getOrganizationId() === item.id && (
                    <Settings
                      color={pallete.colors.white}
                      className='w-[16px] h-[16px]'
                      onClick={(e) => {
                        e.stopPropagation()
                        localStorage.removeItem(StringConstants.ACTIVE_TAB)
                        settingClick && settingClick()
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          ))}
        </div>
      )}
    </div>
  )
}

export default observer(DropdownItem)
