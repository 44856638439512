import React from 'react'

interface StatusProps {
  count: number
  label: string
  textColor: string
  bgColor: string
  borderTopLeftRadius?: string
  borderBottomLeftRadius?: string
  borderTopRightRadius?: string
  borderBottomRightRadius?: string
}

const Status: React.FC<StatusProps> = ({
  count,
  label,
  textColor,
  bgColor,
  borderTopLeftRadius = '0px',
  borderBottomLeftRadius = '0px',
  borderTopRightRadius = '0px',
  borderBottomRightRadius = '0px'
}) => {
  return (
    <div
      className='flex p-[10px] items-center w-full h-[24px]'
      style={{
        backgroundColor: bgColor,
        borderTopLeftRadius: borderTopLeftRadius,
        borderBottomLeftRadius: borderBottomLeftRadius,
        borderTopRightRadius: borderTopRightRadius,
        borderBottomRightRadius: borderBottomRightRadius
      }}
    >
      <span className='text-sm w-full text-center' style={{ color: textColor }}>
        {count} {label}
      </span>
    </div>
  )
}

export default Status
